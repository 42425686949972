import {
  AccessGrantPageInfo,
  AccessGrantRequirementPageInfo as Requirement,
} from '../@types/GetAccessGrantPageInfo';
import {
  Mutation,
  MutationUpdateAccessGrantArgs,
  RequirementCreateWithoutAccessGrantInput,
  RequirementStatus,
  RequirementType,
  RequirementUpdateWithWhereUniqueWithoutAccessGrantInput,
} from '../../../__generated__/graphql';

import { ActionResult } from '../../../components/QuickActionBar/@types/QuickActionBarTypes';
import { GET_ACCESS_GRANT_PAGE_INFO_AND_CHECK_NOTIFICATION_CAPABILITY } from '../graphql/getAccessGrantPageInfo';
import UPDATE_ACCESS_GRANT from '../../../graphql/updateAccessGrant';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { useMutation } from '@apollo/client';

type IUseCheckInLock = {
  isCheckInEnabled: boolean;
  updateCheckInLockStatus: () => Promise<ActionResult>;
  isUpdatingCheckInState: boolean;
};

type UpdateAccessGrantData = Pick<Mutation, 'updateAccessGrant'>;

const useCheckInLock = (accessGrant: AccessGrantPageInfo): IUseCheckInLock => {
  const [upsertPMSConfirmationRequirement, { loading }] = useMutation<
    UpdateAccessGrantData,
    MutationUpdateAccessGrantArgs
  >(UPDATE_ACCESS_GRANT);

  const getPmsConfirmationRequirement = (requirements: Requirement[]): Requirement | undefined => {
    const [pmsConfirmationRequirement] = requirements.filter(
      (requirement) => requirement.type === RequirementType.PmsConfirmation
    );

    if (pmsConfirmationRequirement) {
      return pmsConfirmationRequirement;
    }

    return undefined;
  };

  const isCheckInEnabled = useMemo((): boolean => {
    const { requirements } = accessGrant;
    const requirement = getPmsConfirmationRequirement(requirements);

    if (requirement) {
      return requirement.status !== RequirementStatus.Required;
    }

    return true;
  }, []);

  const updateCheckInLockStatus = async (): Promise<ActionResult> => {
    try {
      const newStatus = !isCheckInEnabled;
      const { id: accessGrantId, requirements } = accessGrant;
      const requirement = getPmsConfirmationRequirement(requirements);

      // Trying to disable the status already disabled
      if (requirement?.status === RequirementStatus.Required && !newStatus) {
        return {
          success: false,
          errorMessage: 'Check-in already disabled',
        };
      }

      // Trying to enable the status already enabled
      if (requirement?.status === RequirementStatus.NotRequired && newStatus) {
        return {
          success: false,
          errorMessage: 'Check-in already enabled',
        };
      }

      const requirementsToCreate: RequirementCreateWithoutAccessGrantInput[] = [];
      const requirementsToUpdate: RequirementUpdateWithWhereUniqueWithoutAccessGrantInput[] = [];

      if (!requirement) {
        requirementsToCreate.push({
          status: newStatus ? RequirementStatus.NotRequired : RequirementStatus.Required,
          type: RequirementType.PmsConfirmation,
        });
      } else {
        requirementsToUpdate.push({
          data: {
            status: { set: newStatus ? RequirementStatus.NotRequired : RequirementStatus.Required },
            type: { set: RequirementType.PmsConfirmation },
          },
          where: {
            id: requirement.id,
          },
        });
      }

      const res = await upsertPMSConfirmationRequirement({
        variables: {
          data: {
            requirements: {
              create: requirementsToCreate,
              update: requirementsToUpdate,
            },
          },
          where: {
            id: accessGrantId,
          },
        },
        // eslint-disable-next-line sort-keys
        refetchQueries: [
          {
            query: GET_ACCESS_GRANT_PAGE_INFO_AND_CHECK_NOTIFICATION_CAPABILITY,
            variables: {
              accessGrantId,
              where: { id: { equals: accessGrantId } },
            },
          },
        ],
      });

      return {
        success: !isEmpty(res.data?.updateAccessGrant),
      };
    } catch (error) {
      return {
        success: false,
        errorMessage: 'An issue ocurred while performing the operation',
      };
    }
  };

  return {
    isCheckInEnabled,
    isUpdatingCheckInState: loading,
    updateCheckInLockStatus,
  };
};

export default useCheckInLock;
