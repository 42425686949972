/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styled, { css, keyframes } from 'styled-components';
import { QuickActionBarStateProps } from '../@types/QuickActionBarTypes';
import Theme from '../../../theme';

const slidInKeyFrames = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
`;

export const Wrapper = styled.nav<QuickActionBarStateProps>`
  display: grid;
  gap: 12px;
  position: fixed;
  z-index: 9;
  top: 100px;
  right: 0;
  width: 55px;
  border-radius: 12px 0 0 12px;
  border: 2px solid ${Theme.colors.lightBorder.hexCode};
  padding: 16px 12px;
  background-color: ${Theme.colors.primaryBg.hexCode};
  transition: width 0.4s ease, border-radius 0.4s ease, right 0.4s ease;
  cursor: pointer;
  animation: ${slidInKeyFrames} 0.5s ease-out;

  ${({ open }) =>
    open &&
    css`
      width: 140px;
      min-width: 140px;
      right: 15px;
      border-radius: 12px;
    `}
`;

export const BarTitle = styled.span<QuickActionBarStateProps>`
  color: white;
  font-size: 21px;
  text-align: center;
  transition: transform 0.5s ease;
  transform-origin: center;

  ${({ open }) => {
    return open
      ? css`
          transform: rotate(0deg);
        `
      : css`
          writing-mode: vertical-rl;
          transform: rotate(-180deg);
          margin-left: -3px;
        `;
  }}
`;

export const BarIcon = styled.div<QuickActionBarStateProps>`
  width: 100%;
  height: 25px;
  align-items: center;
  justify-content: center;
  transition: transform 0.5s ease;

  /* Apply spin animation based on props */
  ${({ open }) =>
    open &&
    css`
      transform: rotate(180deg);
    `}

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Header = styled.div`
  display: grid;
  gap: 12px;
  margin-bottom: 10px;

  ::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
  }
`;
