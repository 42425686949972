import CloseQuickActionModal from '../actions/@types/CloseQuickActionModalAction';
import LogOutAction from '../actions/@types/LogOutAction';
import MeActionEnum from '../../enums/MeAction.enum';
import ModalAction from '../../enums/ModalAction.enum';
import ModalCloseAction from '../actions/@types/ModalCloseAction';
import ModalCloseAllAction from '../actions/@types/ModalCloseAllAction';
import ModalCloseConfirmationAction from '../actions/@types/ModalCloseConfirmationAction';
import ModalCloseFormAction from '../actions/@types/ModalCloseFormAction';
import ModalCloseRemoveAction from '../actions/@types/ModalCloseRemoveAction';
import ModalOpenAction from '../actions/@types/ModalOpenAction';
import ModalOpenAllAction from '../actions/@types/ModalOpenAllAction';
import ModalOpenConfirmationAction from '../actions/@types/ModalOpenConfirmationAction';
import ModalOpenFormAction from '../actions/@types/ModalOpenFormAction';
import ModalOpenQuickActionConfirmationAction from '../actions/@types/ModalOpenQuickActionConfirmationAction';
import ModalOpenQuickActionProcessingAction from '../actions/@types/ModalOpenQuickActionProcessingAction';
import ModalOpenRemoveAction from '../actions/@types/ModalOpenRemoveAction';
import ModalsReduxState from './@types/ModalsReduxState';
import RemoveHookData from '../../hooks/@types/RemoveHookData';
import ResetModalsAction from '../actions/@types/ResetModalsAction';

/* 
  conditional rendering of hooks is not allowed; will cause crash

  mock hook exists so that an undefined hook is not passed to 
  components that subscribe to this data
 */
const MOCK_HOOK = (): RemoveHookData => ({
  error: undefined,
  loading: false,
  mutation: (): void => {},
});

const initialState: ModalsReduxState = {
  form: {
    name: '',
  },
  incomingCall: false,
  kioskActions: false,
  remove: {
    dataType: 'Access Grant',
    details: [],
    hook: MOCK_HOOK,
    id: undefined,
    isOpen: false,
    verbiage: 'remove',
  },
  videoCall: false,
};

export default function modalsReducer(
  state = initialState,
  action:
    | ModalCloseAction
    | ModalCloseAllAction
    | ModalCloseFormAction
    | ModalCloseRemoveAction
    | ModalCloseConfirmationAction
    | ModalOpenFormAction
    | ModalOpenAction
    | ModalOpenAllAction
    | ModalOpenRemoveAction
    | ModalOpenConfirmationAction
    | ModalOpenConfirmationAction
    | ModalOpenQuickActionConfirmationAction
    | ModalOpenQuickActionProcessingAction
    | ResetModalsAction
    | LogOutAction
    | CloseQuickActionModal
): ModalsReduxState {
  switch (action.type) {
    case MeActionEnum.LOG_OUT: {
      return initialState;
    }

    case ModalAction.CLOSE_ALL: {
      const newState: ModalsReduxState = { ...state };

      if (action.payload.modalKeys.length) {
        action.payload.modalKeys.forEach((path: keyof ModalsReduxState) => {
          if (
            path !== 'form' &&
            path !== 'remove' &&
            path !== 'confirmationModal' &&
            path !== 'quickActionConfirmationModal' &&
            path !== 'quickActionProcessingModal'
          ) {
            newState[path] = false;
          }
        });

        return newState;
      }

      return initialState;
    }

    case ModalAction.CLOSE_FORM: {
      return {
        ...state,
        form: {
          name: '',
        },
      };
    }

    case ModalAction.CLOSE_MODAL: {
      return {
        ...state,
        [action.payload.modalKey]: false,
      };
    }

    case ModalAction.CLOSE_REMOVE_MODAL: {
      return {
        ...state,
        remove: {
          hook: MOCK_HOOK,
          isOpen: false,
          verbiage: 'remove',
        },
      };
    }

    case ModalAction.CLOSE_CONFIRMATION_MODAL: {
      return {
        ...state,
        confirmationModal: undefined,
        quickActionConfirmationModal: undefined,
        quickActionProcessingModal: undefined,
      };
    }

    case ModalAction.CLOSE_QUICK_ACTION_MODAL: {
      return {
        ...state,
        [action.payload.modalKey]: undefined,
      };
    }

    case ModalAction.OPEN_ALL: {
      const newState = { ...state };

      action.payload.modalKeys.forEach((path: keyof ModalsReduxState) => {
        if (
          path !== 'form' &&
          path !== 'remove' &&
          path !== 'confirmationModal' &&
          path !== 'quickActionConfirmationModal' &&
          path !== 'quickActionProcessingModal'
        ) {
          newState[path] = true;
        }
      });

      return newState;
    }

    case ModalAction.OPEN_FORM: {
      return {
        ...state,
        form: {
          ...action.payload,
        },
      };
    }

    case ModalAction.OPEN_MODAL: {
      return {
        ...state,
        [action.payload.modalKey]: true,
      };
    }

    case ModalAction.OPEN_REMOVE_MODAL: {
      return {
        ...state,
        remove: {
          isOpen: true,
          verbiage: action.payload.verbiage ?? 'remove',
          ...action.payload,
        },
      };
    }

    case ModalAction.OPEN_CONFIRMATION_MODAL: {
      return {
        ...state,
        confirmationModal: {
          action: action.payload.action,
          message: action.payload.message,
        },
      };
    }

    case ModalAction.OPEN_QUICK_ACTION_CONFIRMATION_MODAL: {
      return {
        ...state,
        quickActionConfirmationModal: {
          action: action.payload.action,
          message: action.payload.message,
          ctaType: action.payload.ctaType,
        },
      };
    }

    case ModalAction.OPEN_QUICK_ACTION_PROCESSING_MODAL: {
      return {
        ...state,
        quickActionProcessingModal: {
          action: action.payload.action,
          message: action.payload.message,
          ctaType: action.payload.ctaType,
        },
      };
    }

    case ModalAction.RESET_MODAL_STORE: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
