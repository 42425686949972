import 'styled-components/macro';

import {
  MdAddAlert,
  MdLocalHotel,
  MdPermContactCalendar,
  MdPerson,
  MdPlace,
  MdSwapVert,
  MdWatch,
} from 'react-icons/md';
import {
  QueryAccessGrantIsEnabledToSendNotificationToExternalApiArgs,
  QueryFindDashboardAccessGrantArgs,
  Role,
} from '../../__generated__/graphql';
import React, { useEffect, useMemo } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CredentialsTab from './components/CredentialsTab';
import DetailPageTemplate from '../../templates/DetailPage';
import { EmailsTab } from './components/EmailsTab';
import { EntityEnum } from '../../@types/client/DashboardUserRolePermissions';
import { GET_ACCESS_GRANT_PAGE_INFO_AND_CHECK_NOTIFICATION_CAPABILITY } from './graphql/getAccessGrantPageInfo';
import GetAccessGrantPageInfo from './@types/GetAccessGrantPageInfo';
import GracePeriodsTab from '../../components/GracePeriodsTab';
import GuestsTab from './components/GuestsTab';
import { NotificationsTab } from './components/NotificationsTab';
import PaymentTransactionsTab from '../Location/components/PaymentTransactionsTab';
import { QuickActionBar } from '../../components/QuickActionBar';
import RequirementsTab from './components/RequirementsTab';
import ResourcesTab from './components/ResourcesTab';
import { RootState } from '../../redux/reducers';
import { flashApolloError } from '../../util/errorUtils';
import getRoomNumber from './functions/getRoomNumber';
import links from '../../util/links';
import { openForm } from '../../redux/actions/modals.actions';
import { setAccessGrantPageInfo } from '../../redux/actions/accessGrant.actions';
import useDateTimeFormatter from '../../hooks/useDateTimeFormatter';
import { useLazyQuery } from '@apollo/client';
import useRemoveAccessGrant from '../../hooks/useRemoveAccessGrant';
import { validateAccessByMembership } from '../../util/authorization';

type CustomAccessGrantArgs = QueryAccessGrantIsEnabledToSendNotificationToExternalApiArgs &
  QueryFindDashboardAccessGrantArgs;

interface CustomGetAccessGrantPageInfo extends GetAccessGrantPageInfo {
  accessGrantIsEnabledToSendNotificationToExternalAPI: boolean;
}

const AccessGrantPage: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const membership = useSelector((state: RootState) => state.me.activeMembership);
  const { accessGrantId } = useParams<{ accessGrantId: string }>();
  const { formatDate, formatDateAndTime } = useDateTimeFormatter();
  const dashboardPreferences = useSelector((state: RootState) => state.dashboardPreferences);

  const [getAccessGrant, { called, data }] = useLazyQuery<
    CustomGetAccessGrantPageInfo,
    CustomAccessGrantArgs
  >(GET_ACCESS_GRANT_PAGE_INFO_AND_CHECK_NOTIFICATION_CAPABILITY, {
    fetchPolicy: 'cache-and-network',
    onError: flashApolloError,
    variables: {
      accessGrantId,
      where: { id: { equals: accessGrantId } },
    },
  });

  useEffect(() => {
    if (!called) {
      getAccessGrant();
    }
  }, [called, getAccessGrant]);

  const accessGrant = data?.findDashboardAccessGrant;
  const accessGrantIsEnabledToSendNotificationToExternalAPI =
    data?.accessGrantIsEnabledToSendNotificationToExternalAPI;

  const shouldDisplayQuickActionsBar = useMemo(() => {
    const quickActionPreferences = dashboardPreferences?.quickActionBar;
    const actionsList = Object.values(dashboardPreferences?.quickActionBar?.actions || {});

    // At least one of the action preferences item is active
    const atLeastOneActionItemIsEnabled = actionsList.some(
      (ctaShouldBeActive) => ctaShouldBeActive !== false
    );

    return (
      quickActionPreferences?.enabled !== false &&
      (actionsList.length === 0 || atLeastOneActionItemIsEnabled)
    );
  }, [dashboardPreferences]);

  if (!accessGrant) {
    return (
      <DetailPageTemplate
        dataType="Access Grant"
        detailItems={[]}
        identifier={accessGrantId}
        permission={EntityEnum.ACCESS_GRANT}
        primaryHeader={`Access Grant`}
        remove={useRemoveAccessGrant}
        secondaryHeader="AG Details"
        tabs={[]}
      />
    );
  }

  dispatch(setAccessGrantPageInfo(accessGrant));

  try {
    validateAccessByMembership({
      membership,
      objectMembership: { locationId: accessGrant.location.id, tenantId: accessGrant.tenantId },
      validateLocation: true,
    });
  } catch {
    return <Redirect to={links.paths.ACCESS_GRANTS} />;
  }

  const getAGDates = (): string => {
    if (!accessGrant.startDate || !accessGrant.endDate) {
      return '';
    }

    return `${formatDate(accessGrant.startDate)} -> ${formatDate(accessGrant.endDate)}`;
  };

  return (
    <>
      <DetailPageTemplate
        dataType="Access Grant"
        detailItems={[
          {
            icon: MdPerson,
            label: 'Guest',
            link: `${links.paths.USERS}/${accessGrant.user.id}`,
            text: accessGrant.user.name ?? accessGrant.user.email ?? '',
          },
          {
            icon: MdPlace,
            label: 'Location',
            link: `${links.paths.LOCATIONS}/${accessGrant.location.id}`,
            text: accessGrant.location.name,
          },
          {
            icon: MdLocalHotel,
            label: 'Room #',
            text: getRoomNumber(accessGrant.resources) ?? 'Unassigned',
          },
          {
            icon: MdWatch,
            label: 'In/Out',
            text: `${accessGrant.startTime}/${accessGrant.endTime}`,
          },
          {
            icon: MdPermContactCalendar,
            label: 'Dates',
            text: getAGDates(),
          },
          {
            icon: MdWatch,
            label: 'ETA',
            text: accessGrant.arrivalTime
              ? formatDateAndTime(accessGrant.arrivalTime as string, {
                  timezone: accessGrant.location.timezone,
                })
              : '',
          },
          {
            icon: MdAddAlert,
            label: 'Status',
            text: accessGrant.status,
          },
          {
            icon: MdSwapVert,
            label: 'Last polled at',
            text: `${
              accessGrant.lastPolledAt
                ? formatDateAndTime(accessGrant.lastPolledAt, {
                    timezone: accessGrant.location.timezone,
                  })
                : 'N/A'
            }`,
          },
        ]}
        identifier={accessGrantId}
        onEditButtonClick={(): void => {
          dispatch(openForm('accessGrant', accessGrantId));
        }}
        permission={EntityEnum.ACCESS_GRANT}
        primaryHeader={`Access Grant: ${accessGrant.confirmationCode}`}
        remove={useRemoveAccessGrant}
        secondaryHeader="AG Details"
        tabs={[
          {
            Component: function userTab(): React.ReactElement {
              return <GuestsTab accessGrant={accessGrant} />;
            },
            id: 'Guests Details',
          },
          ...(membership.role === Role.Admin
            ? [
                {
                  Component: function requirementsTab(): React.ReactElement {
                    return <CredentialsTab accessGrant={accessGrant} />;
                  },
                  id: 'Credentials',
                },
              ]
            : []),
          {
            Component: function requirementsTab(): React.ReactElement {
              return (
                <RequirementsTab
                  requirements={accessGrant.requirements}
                  locationTimezone={accessGrant.location.timezone}
                />
              );
            },
            id: 'Requirements',
          },
          {
            Component: ResourcesTab,
            id: 'Resources',
          },
          {
            Component: PaymentTransactionsTab,
            id: 'Payment Transactions',
          },
          {
            Component: function emailTab(): React.ReactElement {
              return <EmailsTab accessGrant={accessGrant} />;
            },
            id: 'Emails',
          },
          {
            Component: function gracePeriodsTab(): React.ReactElement {
              return (
                <GracePeriodsTab
                  accessGrantId={accessGrant.id}
                  gracePeriodsConfigLevel="ACCESS_GRANT"
                />
              );
            },
            id: 'Access Setup',
          },
          ...(accessGrantIsEnabledToSendNotificationToExternalAPI
            ? [
                {
                  Component: function notificationsTab(): React.ReactElement {
                    return <NotificationsTab accessGrant={accessGrant} />;
                  },
                  id: 'Notifications',
                },
              ]
            : []),
        ]}
      />
      {shouldDisplayQuickActionsBar && <QuickActionBar />}
    </>
  );
};

export default AccessGrantPage;
