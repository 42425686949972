import { ActionResult, RemotePrintKioskFormValues } from '../@types/QuickActionBarTypes';
import { CustomForm, FormText } from '../styles/RemoteKeyDispensingModal.styles';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import {
  Mutation,
  MutationIssueKeyForKioskWithAccessGrantIdArgs,
} from '../../../__generated__/graphql';
import React, { memo, useCallback } from 'react';
import { closeForm, openQuickActionProcessingModal } from '../../../redux/actions/modals.actions';
import { useDispatch, useSelector } from 'react-redux';
import BaseQuickActionModal from './BaseQuickActionModal';
import ISSUE_KEY_FOR_KIOSK_WITH_ACCESS_GRANT_ID from '../../../graphql/issueKeyForKioskWithAccessGrantId';
import InputGroupProps from '../../InputGroup/@types/InputGroupProps';
import { REMOTE_DISPENSE_KEY_VALIDATION_SCHEMA } from '../constants/REMOTE_DISPENSE_KEY_VALIDATION_SCHEMA';
import { RootState } from '../../../redux/reducers';
import SimpleFormSection from '../../../templates/SimpleFormSection';
import useLoadKioskOptions from '../hooks/useLoadKioskOptions';
import { useMutation } from '@apollo/client';
import useSearchKioskByName from '../hooks/useSearchKioskByName';
import { yupResolver } from '@hookform/resolvers/yup';

export const KEY_DISPENSE_FORM = 'KEY_DISPENSE_FORM';

const Component: React.FC = () => {
  const form = useForm<RemotePrintKioskFormValues>({
    defaultValues: {},
    resolver: yupResolver(REMOTE_DISPENSE_KEY_VALIDATION_SCHEMA),
    reValidateMode: 'onChange',
  });
  const dispatch = useDispatch();
  const accessGrant = useSelector((state: RootState) => state.accessGrant.pageInfo);

  type IssueKeyResponseData = Pick<Mutation, 'issueKeyForKioskWithAccessGrantId'>;

  const [issueKeyForKioskWithAccessGrantId] = useMutation<
    IssueKeyResponseData,
    MutationIssueKeyForKioskWithAccessGrantIdArgs
  >(ISSUE_KEY_FOR_KIOSK_WITH_ACCESS_GRANT_ID);

  const inputs: InputGroupProps[] = [
    {
      label: 'Search Kiosk',
      loadOptions: useLoadKioskOptions,
      name: 'kiosk',
      query: useSearchKioskByName,
      type: 'async-select',
      required: true,
    },
  ];

  const sendDispenseKeyCommand = useCallback(async (kioskId: string): Promise<ActionResult> => {
    try {
      const accessGrantId = accessGrant?.id;

      if (!accessGrantId || !kioskId) {
        throw new Error('Kiosk and Access Grant configurations are not valid');
      }

      const result = await issueKeyForKioskWithAccessGrantId({
        variables: { accessGrantId, kioskId },
      });

      const success = result.data?.issueKeyForKioskWithAccessGrantId.success === true;

      if (!success) {
        throw new Error('The command was not successfully processed by the server');
      }

      return {
        success,
      };
    } catch (error) {
      const { message } = error as Error;

      return {
        success: false,
        errorMessage: `Error sending command: ${message}`,
      };
    }
  }, []);

  const onSubmit: SubmitHandler<RemotePrintKioskFormValues> = (data): void => {
    const kioskId = data.kiosk.value;

    dispatch(closeForm());

    dispatch(
      openQuickActionProcessingModal({
        action: () => sendDispenseKeyCommand(kioskId),
        ctaType: 'remotePrintKeyCard',
      })
    );
  };

  return (
    <BaseQuickActionModal
      ctaType="remotePrintKeyCard"
      confirmButton={{
        action: (): void => {
          form.handleSubmit(onSubmit)();
        },
      }}
      cancelButton={{
        action: (): void => {
          dispatch(closeForm());
        },
      }}
      headerTitle="Select a kiosk to dispense a key"
    >
      <FormProvider {...form}>
        <CustomForm>
          <SimpleFormSection inputs={inputs} columns={1} />
        </CustomForm>
      </FormProvider>
      <FormText>
        Confirming this action will dispense a key card at the selected kiosk. Please ensure the
        guest is present at the kiosk.
      </FormText>
    </BaseQuickActionModal>
  );
};

export const RemoteKeyDispensingModal = memo(Component);
