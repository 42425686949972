import { QuickActionCTAType } from '../@types/QuickActionBarTypes';
import { useMemo } from 'react';

type CtaBasedText = {
  title: string;
  message?: string;
  errorHeader?: string;
};

export const useCtaBasedText = (ctaType: QuickActionCTAType): CtaBasedText => {
  const ctaBasedText = useMemo((): CtaBasedText => {
    switch (ctaType) {
      case 'checkIn':
        return {
          title: 'Check in this guest',
          errorHeader: 'Error Checking in',
          message: 'By confirming this you are waiving any pending requirement of this guest.',
        };
      case 'checkOut':
        return {
          title: 'Check out this guest',
          errorHeader: 'Error Checking out',
          message: 'By confirming this you are waiving any pending requirement of this guest.',
        };
      case 'blockCheckIn':
        return {
          title: 'Block guest check-in',
          errorHeader: 'Error Blocking Check-in',
          message: 'By confirming this you will make the guest unable to perform check-in',
        };
      case 'getAutenticationCredentials':
        return {
          title: 'Get guests credentials',
          errorHeader: 'Error getting credentials',
        };
      case 'remotePrintKeyCard':
        return {
          title: 'Error Dispensing Key',
          errorHeader: 'Error dispensing key',
          message:
            'Confirming this action will dispense a key card at the selected kiosk. Please ensure the guest is present at the kiosk.',
        };
      case 'repollReservation':
        return {
          title: 'Re-poll reservation',
          errorHeader: 'Error repolling reservation',
        };
      default:
        return {
          title: 'Are you sure you want to do this?',
        };
    }
  }, []);

  return ctaBasedText;
};
