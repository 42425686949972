/* eslint-disable sort-keys */

import { JSONSchema7 } from 'json-schema';

export const mobileLocalizationSchema: JSONSchema7 = {
  type: 'object',
  properties: {
    general: {
      type: 'object',
      title: '#1 General (Mobile App & Web)',
      properties: {
        'mobileApp:label': {
          title: 'Mobile App Section',
          type: 'object',
          description: '[general] Localization for mobile app',
          properties: {},
        },
        'error:enable': {
          title: '#1 Error',
          type: 'boolean',
          description: '[general.error]',
        },
        error: {
          type: 'string',
        },
        'or:enable': {
          title: '#2 Or',
          type: 'boolean',
          description: '[general.or]',
        },
        or: {
          type: 'string',
        },
        'continue:enable': {
          title: '#3 Continue',
          type: 'boolean',
          description: '[general.continue]',
        },
        continue: {
          type: 'string',
        },
        'submit:enable': {
          title: '#4 Submit',
          type: 'boolean',
          description: '[general.submit]',
        },
        submit: {
          type: 'string',
        },
        'signinAnotherWay:enable': {
          title: '#5 Sign in another way',
          type: 'boolean',
          description: '[general.signinAnotherWay]',
        },
        signinAnotherWay: {
          type: 'string',
        },
        'cancel:enable': {
          title: '#6 Cancel',
          type: 'boolean',
          description: '[general.cancel]',
        },
        cancel: {
          type: 'string',
        },
        'done:enable': {
          title: '#7 Done',
          type: 'boolean',
          description: '[general.done]',
        },
        done: {
          type: 'string',
        },
        'remove:enable': {
          title: '#8 Remove',
          type: 'boolean',
          description: '[general.remove]',
        },
        remove: {
          type: 'string',
        },
        'start:enable': {
          title: '#9 Start',
          type: 'boolean',
          description: '[general.start]',
        },
        start: {
          type: 'string',
        },
        'firstNamePlaceholder:enable': {
          title: '#10 First name placeholder',
          type: 'boolean',
          description: '[general.firstNamePlaceholder]',
        },
        firstNamePlaceholder: {
          type: 'string',
        },
        'lastNamePlaceholder:enable': {
          title: '#11 Last name placeholder',
          type: 'boolean',
          description: '[general.lastNamePlaceholder]',
        },
        lastNamePlaceholder: {
          type: 'string',
        },
        'emailPlaceholder:enable': {
          title: '#12 Email placeholder',
          type: 'boolean',
          description: '[general.emailPlaceholder]',
        },
        emailPlaceholder: {
          type: 'string',
        },
        'phoneNumberPlaceholder:enable': {
          title: '#13 Phone number placeholder',
          type: 'boolean',
          description: '[general.phoneNumberPlaceholder]',
        },
        phoneNumberPlaceholder: {
          type: 'string',
        },
        'addPaymentFailedTitle:enable': {
          title: '#14 Generic payment requirement failed popup title',
          type: 'boolean',
          description: '[general.addPaymentFailedTitle]',
        },
        addPaymentFailedTitle: {
          type: 'string',
        },
        'addPaymentFailedButtonText:enable': {
          title: '#15 Generic payment requirement failed popup button text',
          type: 'boolean',
          description: '[general.addPaymentFailedButtonText]',
        },
        addPaymentFailedButtonText: {
          type: 'string',
        },
        'floorPrefix:enable': {
          title: '#16 Floor label prefix',
          type: 'boolean',
          description: '[general.floorPrefix]',
        },
        floorPrefix: {
          type: 'string',
        },
        'floor:enable': {
          title: '#17 Floor label',
          type: 'boolean',
          description: '[general.floor]',
        },
        floor: {
          type: 'string',
        },
        'floorSuffix:enable': {
          title: '#18 Floor label suffix',
          type: 'boolean',
          description: '[general.floorSuffix]',
        },
        floorSuffix: {
          type: 'string',
        },
        'buildingPrefix:enable': {
          title: '#19 Building label prefix',
          type: 'boolean',
          description: '[general.buildingPrefix]',
        },
        buildingPrefix: {
          type: 'string',
        },
        'building:enable': {
          title: '#20 Building label',
          type: 'boolean',
          description: '[general.building]',
        },
        building: {
          type: 'string',
        },
        'buildingSuffix:enable': {
          title: '#21 Building label suffix',
          type: 'boolean',
          description: '[general.buildingSuffix]',
        },
        buildingSuffix: {
          type: 'string',
        },
        'forceUpdateTitle:enable': {
          title: '#22 Force update popup title',
          type: 'boolean',
          description: '[general.forceUpdateTitle]',
        },
        forceUpdateTitle: {
          type: 'string',
        },
        'forceUpdateButton:enable': {
          title: '#23 Force update popup button',
          type: 'boolean',
          description: '[general.forceUpdateButton]',
        },
        forceUpdateButton: {
          type: 'string',
        },
        'mobileWeb:label': {
          title: 'Mobile Web Section',
          type: 'object',
          description: '[general] Localization for mobile web',
          properties: {},
        },
        'checkIn:enable': {
          title: '#1 Check-in',
          type: 'boolean',
          description: '[general.checkIn]',
        },
        checkIn: {
          type: 'string',
        },
        'downloadMobileApp:enable': {
          title: '#2 Download Mobile App',
          type: 'boolean',
          description: '[general.downloadMobileApp]',
        },
        downloadMobileApp: {
          type: 'string',
        },
        'verifyId:enable': {
          title: '#3 Verify ID',
          type: 'boolean',
          description: '[general.verifyId]',
        },
        verifyId: {
          type: 'string',
        },
        'paymentAuthorization:enable': {
          title: '#4 Payment Authorization',
          type: 'boolean',
          description: '[general.paymentAuthorization]',
        },
        paymentAuthorization: {
          type: 'string',
        },
        'paymentStatus:enable': {
          title: '#5 Payment Status',
          type: 'boolean',
          description: '[general.paymentStatus]',
        },
        paymentStatus: {
          type: 'string',
        },
        'paymentCapture:enable': {
          title: '#6 Payment Capture',
          type: 'boolean',
          description: '[general.paymentCapture]',
        },
        paymentCapture: {
          type: 'string',
        },
        'termsAndConditions:enable': {
          title: '#7 Terms And Conditions',
          type: 'boolean',
          description: '[general.termsAndConditions]',
        },
        termsAndConditions: {
          type: 'string',
        },
        'next:enable': {
          title: '#8 Next',
          type: 'boolean',
          description: '[general.next]',
        },
        next: {
          type: 'string',
        },
        'retry:enable': {
          title: '#9 Retry',
          type: 'boolean',
          description: '[general.retry]',
        },
        retry: {
          type: 'string',
        },
        'clear:enable': {
          title: '#10 Clear',
          type: 'boolean',
          description: '[general.clear]',
        },
        clear: {
          type: 'string',
        },
        'disabledOnDesktop:enable': {
          title: '#11 Disabled on Desktop',
          type: 'boolean',
          description: '[general.disabledOnDesktop]',
        },
        disabledOnDesktop: {
          type: 'string',
        },
        'mrzIdVerification:enable': {
          title: '#12 MRZ ID Verification requirement',
          type: 'boolean',
          description: '[general.mrzIdVerification]',
        },
        mrzIdVerification: {
          type: 'string',
        },
        'walletPass:enable': {
          title: '#13 Wallet pass',
          type: 'boolean',
          description: '[general.walletPass]',
        },
        walletPass: {
          type: 'string',
        },
        'cameraPermissionDeniediOS:enable': {
          title: '#14 Camera permission denied text (iOS)',
          type: 'boolean',
          description: '[general.cameraPermissionDeniediOS]',
        },
        cameraPermissionDeniediOS: {
          type: 'string',
        },
        'cameraPermissionDeniedAndroid:enable': {
          title: '#15 Camera permission denied text (Android)',
          type: 'boolean',
          description: '[general.cameraPermissionDeniedAndroid]',
        },
        cameraPermissionDeniedAndroid: {
          type: 'string',
        },
        'humanReadableErrors:enable': {
          title: '#16 Human readable errors',
          type: 'boolean',
          description: '[general.humanReadableErrors]',
        },
        humanReadableErrors: {
          title: 'Localize error codes to be human readable errors',
          type: 'array',
          items: {
            type: 'object',
            properties: {
              code: {
                title: 'Error code',
                type: 'string',
              },
              message: {
                title: 'Human readable error text',
                type: 'string',
              },
            },
            required: ['code', 'message'],
          },
        },
      },
    },
    updatePasswordScreen: {
      type: 'object',
      title: '#2 Update password screen',
      properties: {
        'setPasswordSuccessfully:enable': {
          title: '#1 Set password successfully',
          type: 'boolean',
          description: '[updatePasswordScreen.setPasswordSuccessfully]',
        },
        setPasswordSuccessfully: {
          type: 'string',
        },
        'passwordMismatch:enable': {
          title: '#2 Password mismatch',
          type: 'boolean',
          description: '[updatePasswordScreen.passwordMismatch]',
        },
        passwordMismatch: {
          type: 'string',
        },
        'setPassword:enable': {
          title: '#3 setPassword',
          type: 'boolean',
          description: '[updatePasswordScreen.setPassword]',
        },
        setPassword: {
          type: 'string',
        },
        'password:enable': {
          title: '#4 Password',
          type: 'boolean',
          description: '[updatePasswordScreen.password]',
        },
        password: {
          type: 'string',
        },
        'confirmationPassword:enable': {
          title: '#5 confirmationPassword',
          type: 'boolean',
          description: '[updatePasswordScreen.confirmationPassword]',
        },
        confirmationPassword: {
          type: 'string',
        },
      },
    },
    captureVendorIDScreen: {
      type: 'object',
      title: '#3 Capture vendor ID screen',
      properties: {
        'vendorCard:enable': {
          title: '#1 vendorCard',
          type: 'boolean',
          description: '[captureVendorIDScreen.vendorCard]',
        },
        vendorCard: {
          type: 'string',
        },
        'snap:enable': {
          title: '#2 snap',
          type: 'boolean',
          description: '[captureVendorIDScreen.snap]',
        },
        snap: {
          type: 'string',
        },
        'screenTitle:enable': {
          title: '#3 screenTitle',
          type: 'boolean',
          description: '[captureVendorIDScreen.screenTitle]',
        },
        screenTitle: {
          type: 'string',
        },
      },
    },
    personalDetailsScreen: {
      type: 'object',
      title: '#4 Personal details screen',
      properties: {
        'screenTitle:enable': {
          title: '#1 Screen title',
          type: 'boolean',
          description: '[personalDetailsScreen.screenTitle]',
        },
        screenTitle: {
          type: 'string',
        },
        'loading:enable': {
          title: '#2 Loading',
          type: 'boolean',
          description: '[personalDetailsScreen.loading]',
        },
        loading: {
          type: 'string',
        },
        'error:enable': {
          title: '#3 Error',
          type: 'boolean',
          description: '[personalDetailsScreen.error]',
        },
        error: {
          type: 'string',
        },
        'personalInformation:enable': {
          title: '#4 personalInformation',
          type: 'boolean',
          description: '[personalDetailsScreen.personalInformation]',
        },
        personalInformation: {
          type: 'string',
        },
      },
    },
    dashboardScreen: {
      type: 'object',
      title: '#5 Dashboard screen',
      properties: {
        'completeThisTask:enable': {
          title: '#1 Complete this task',
          type: 'boolean',
          description: '[dashboardScreen.completeThisTask]',
        },
        completeThisTask: {
          type: 'string',
        },
        'noAccessGrant:enable': {
          title: '#2 No access grant',
          type: 'boolean',
          description: '[dashboardScreen.noAccessGrant]',
        },
        noAccessGrant: {
          type: 'string',
        },
        'idVerification:enable': {
          title: '#3 ID verification',
          type: 'boolean',
          description: '[dashboardScreen.idVerification]',
        },
        idVerification: {
          type: 'string',
        },
        'addPayment:enable': {
          title: '#4 Add payment',
          type: 'boolean',
          description: '[dashboardScreen.addPayment]',
        },
        addPayment: {
          type: 'string',
        },
        'addAuthorization:enable': {
          title: '#5 Add authorization',
          type: 'boolean',
          description: '[dashboardScreen.addAuthorization]',
        },
        addAuthorization: {
          type: 'string',
        },
        'securityDeposit:enable': {
          title: '#6 Security deposit',
          type: 'boolean',
          description: '[dashboardScreen.securityDeposit]',
        },
        securityDeposit: {
          type: 'string',
        },
        'yourStayTab:enable': {
          title: '#7 Your stay tab',
          type: 'boolean',
          description: '[dashboardScreen.yourStayTab]',
        },
        yourStayTab: {
          type: 'string',
        },
        'servicesTab:enable': {
          title: '#8 Services tab',
          type: 'boolean',
          description: '[dashboardScreen.servicesTab]',
        },
        servicesTab: {
          type: 'string',
        },
        'stayTab:enable': {
          title: '#9 Stay tab',
          type: 'boolean',
          description: '[dashboardScreen.stayTab]',
        },
        stayTab: {
          type: 'string',
        },
        'yourTourTab:enable': {
          title: '#10 Your tour tab',
          type: 'boolean',
          description: '[dashboardScreen.yourTourTab]',
        },
        yourTourTab: {
          type: 'string',
        },
        'buildingTab:enable': {
          title: '#11 Building tab',
          type: 'boolean',
          description: '[dashboardScreen.buildingTab]',
        },
        buildingTab: {
          type: 'string',
        },
        'tourTab:enable': {
          title: '#12 Tour tab',
          type: 'boolean',
          description: '[dashboardScreen.tourTab]',
        },
        tourTab: {
          type: 'string',
        },
        'amenitiesMenu:enable': {
          title: '#13 Amenities menu',
          type: 'boolean',
          description: '[dashboardScreen.amenitiesMenu]',
        },
        amenitiesMenu: {
          type: 'string',
        },
        'keyCardMenu:enable': {
          title: '#14 Key card menu',
          type: 'boolean',
          description: '[dashboardScreen.keyCardMenu]',
        },
        keyCardMenu: {
          type: 'string',
        },
        'qrMenu:enable': {
          title: '#15 QR menu',
          type: 'boolean',
          description: '[dashboardScreen.qrMenu]',
        },
        qrMenu: {
          type: 'string',
        },
        'infoMenu:enable': {
          title: '#16 Info menu',
          type: 'boolean',
          description: '[dashboardScreen.infoMenu]',
        },
        infoMenu: {
          type: 'string',
        },
        'reservation:enable': {
          title: '#17 Reservation',
          type: 'boolean',
          description: '[dashboardScreen.reservation]',
        },
        reservation: {
          type: 'string',
        },
        'nextTour:enable': {
          title: '#18 Next tour',
          type: 'boolean',
          description: '[dashboardScreen.nextTour]',
        },
        nextTour: {
          type: 'string',
        },
        'checkinLabelLeasing:enable': {
          title: '#19 Check-in label leasing',
          type: 'boolean',
          description: '[dashboardScreen.checkinLabelLeasing]',
        },
        checkinLabelLeasing: {
          type: 'string',
        },
        'checkinLabelHotel:enable': {
          title: '#20 Check-in label hotel',
          type: 'boolean',
          description: '[dashboardScreen.checkinLabelHotel]',
        },
        checkinLabelHotel: {
          type: 'string',
        },
        'checkoutLabelHotel:enable': {
          title: '#21 Check-out label hotel',
          type: 'boolean',
          description: '[dashboardScreen.checkoutLabelHotel]',
        },
        checkoutLabelHotel: {
          type: 'string',
        },
        'clickForMore:enable': {
          title: '#22 Click for more',
          type: 'boolean',
          description: '[dashboardScreen.clickForMore]',
        },
        clickForMore: {
          type: 'string',
        },
        'roomNumberWillShown:enable': {
          title: '#23 Room number will shown',
          type: 'boolean',
          description: '[dashboardScreen.roomNumberWillShown]',
        },
        roomNumberWillShown: {
          type: 'string',
        },
        'showAll:enable': {
          title: '#24 Show all',
          type: 'boolean',
          description: '[dashboardScreen.showAll]',
        },
        showAll: {
          type: 'string',
        },
        'failedToSendServiceRequest:enable': {
          title: '#25 Failed to send service request',
          type: 'boolean',
          description: '[dashboardScreen.failedToSendServiceRequest]',
        },
        failedToSendServiceRequest: {
          type: 'string',
        },
        'youHaveSelected:enable': {
          title: '#26 You have selected',
          type: 'boolean',
          description: '[dashboardScreen.youHaveSelected]',
        },
        youHaveSelected: {
          type: 'string',
        },
        'comment:enable': {
          title: '#27 Comment',
          type: 'boolean',
          description: '[dashboardScreen.comment]',
        },
        comment: {
          type: 'string',
        },
        'requestSent:enable': {
          title: '#28 Request sent',
          type: 'boolean',
          description: '[dashboardScreen.requestSent]',
        },
        requestSent: {
          type: 'string',
        },
        'sendRequest:enable': {
          title: '#29 Send request',
          type: 'boolean',
          description: '[dashboardScreen.sendRequest]',
        },
        sendRequest: {
          type: 'string',
        },
        'time:enable': {
          title: '#30 Time',
          type: 'boolean',
          description: '[dashboardScreen.time]',
        },
        time: {
          type: 'string',
        },
        'availableDuringCheckin:enable': {
          title: '#31 Available during check-in',
          type: 'boolean',
          description: '[dashboardScreen.availableDuringCheckin]',
        },
        availableDuringCheckin: {
          type: 'string',
        },
        'pleaseCompleteAllRequirement:enable': {
          title: '#32 Please complete all requirement',
          type: 'boolean',
          description: '[dashboardScreen.pleaseCompleteAllRequirement]',
        },
        pleaseCompleteAllRequirement: {
          type: 'string',
        },
        'serviceAvailableAfterCheckin:enable': {
          title: '#33 Service available after check-in',
          type: 'boolean',
          description: '[dashboardScreen.serviceAvailableAfterCheckin]',
        },
        serviceAvailableAfterCheckin: {
          type: 'string',
        },
        'completeAllRequirement:enable': {
          title: '#34 Complete all requirement',
          type: 'boolean',
          description: '[dashboardScreen.completeAllRequirement]',
        },
        completeAllRequirement: {
          type: 'string',
        },
        'issueKeycard:enable': {
          title: '#35 Issue key card',
          type: 'boolean',
          description: '[dashboardScreen.issueKeycard]',
        },
        issueKeycard: {
          type: 'string',
        },
        'tapToUse:enable': {
          title: '#36 Tap to use',
          type: 'boolean',
          description: '[dashboardScreen.tapToUse]',
        },
        tapToUse: {
          type: 'string',
        },
        'lockServiceCode:enable': {
          title: '#37 Lock service code',
          type: 'boolean',
          description: '[dashboardScreen.lockServiceCode]',
        },
        lockServiceCode: {
          type: 'string',
        },
        'blueTooth:enable': {
          title: '#38 Bluetooth',
          type: 'boolean',
          description: '[dashboardScreen.blueTooth]',
        },
        blueTooth: {
          type: 'string',
        },
        'stableNetwork:enable': {
          title: '#39 Stable network',
          type: 'boolean',
          description: '[dashboardScreen.stableNetwork]',
        },
        stableNetwork: {
          type: 'string',
        },
        'failedToCreateMobileKey:enable': {
          title: '#40 Failed to create mobile key',
          type: 'boolean',
          description: '[dashboardScreen.failedToCreateMobileKey]',
        },
        failedToCreateMobileKey: {
          type: 'string',
        },
        'holdNearScanner:enable': {
          title: '#41 Hold near scanner',
          type: 'boolean',
          description: '[dashboardScreen.holdNearScanner]',
        },
        holdNearScanner: {
          type: 'string',
        },
        'food:enable': {
          title: '#42 Food',
          type: 'boolean',
          description: '[dashboardScreen.food]',
        },
        food: {
          type: 'string',
        },
        'room:enable': {
          title: '#43 Room',
          type: 'boolean',
          description: '[dashboardScreen.room]',
        },
        room: {
          type: 'string',
        },
        'yourBooking:enable': {
          title: '#44 Your booking',
          type: 'boolean',
          description: '[dashboardScreen.yourBooking]',
        },
        yourBooking: {
          type: 'string',
        },
        'confirmationAGSheet:enable': {
          title: '#45 Confirmation AG sheet',
          type: 'boolean',
          description: '[dashboardScreen.confirmationAGSheet]',
        },
        confirmationAGSheet: {
          type: 'string',
        },
        'checkinAGSheet:enable': {
          title: '#46 Check-in AG sheet',
          type: 'boolean',
          description: '[dashboardScreen.checkinAGSheet]',
        },
        checkinAGSheet: {
          type: 'string',
        },
        'scanToUse:enable': {
          title: '#47 Scan to use',
          type: 'boolean',
          description: '[dashboardScreen.scanToUse]',
        },
        scanToUse: {
          type: 'string',
        },
        'remoteAssistanceRequirementOverlayTitle:enable': {
          title: '#48 Remote assistance requirement overlay title',
          type: 'boolean',
          description: '[dashboardScreen.remoteAssistanceRequirementOverlayTitle]',
        },
        remoteAssistanceRequirementOverlayTitle: {
          type: 'string',
        },
        'remoteAssistanceRequirementOverlayButtonTitle:enable': {
          title: '#49 Remote assistance requirement overlay button title',
          type: 'boolean',
          description: '[dashboardScreen.remoteAssistanceRequirementOverlayButtonTitle]',
        },
        remoteAssistanceRequirementOverlayButtonTitle: {
          type: 'string',
        },
        'requestTitle:enable': {
          title: '#50 Request title',
          type: 'boolean',
          description: '[dashboardScreen.requestTitle]',
        },
        requestTitle: {
          type: 'string',
        },
        'checkInFailureAlertTitle:enable': {
          title: '#51 Check-in failure alert title',
          type: 'boolean',
          description: '[dashboardScreen.checkInFailureAlertTitle]',
        },
        checkInFailureAlertTitle: {
          type: 'string',
        },
        'checkInFailureAlertDescription:enable': {
          title: '#52 Check-in failure alert description',
          type: 'boolean',
          description: '[dashboardScreen.checkInFailureAlertDescription]',
        },
        checkInFailureAlertDescription: {
          type: 'string',
        },
        'requirementsFailureAlertTitle:enable': {
          title: '#53 Requirements failure alert title',
          type: 'boolean',
          description: '[dashboardScreen.requirementsFailureAlertTitle]',
        },
        requirementsFailureAlertTitle: {
          type: 'string',
        },
        'keycardRoomNumberSuffixText:enable': {
          title: '#54 Suffix for the keycard room # text',
          type: 'boolean',
          description: '[dashboardScreen.keycardRoomNumberSuffixText]',
        },
        keycardRoomNumberSuffixText: {
          type: 'string',
        },
        'keycardHeaderText:enable': {
          title: '#55 Keycard header text',
          type: 'boolean',
          description: '[dashboardScreen.keycardHeaderText]',
        },
        keycardHeaderText: {
          type: 'string',
        },
        'limitKeysReached:enable': {
          title: '#56 Limit keys reached error message',
          type: 'boolean',
          description: '[dashboardScreen.limitKeysReached]',
        },
        limitKeysReached: {
          type: 'string',
        },
        'scanLockListAgain:enable': {
          title: '#57 Scan lock list again',
          type: 'boolean',
          description: '[dashboardScreen.scanLockListAgain]',
        },
        scanLockListAgain: {
          type: 'string',
        },
        'requirementOverlayTitleStart:enable': {
          title: '#58 Requirement overlay start button text',
          type: 'boolean',
          description: '[dashboardScreen.requirementOverlayTitleStart]',
        },
        requirementOverlayTitleStart: {
          type: 'string',
        },
      },
    },
    paymentInfoScreen: {
      type: 'object',
      title: '#6 Payment info screen',
      properties: {
        'removeCreditCard:enable': {
          title: '#1 Remove credit card',
          type: 'boolean',
          description: '[paymentInfoScreen.removeCreditCard]',
        },
        removeCreditCard: {
          type: 'string',
        },
        'creditCard:enable': {
          title: '#2 Credit card',
          type: 'boolean',
          description: '[paymentInfoScreen.creditCard]',
        },
        creditCard: {
          type: 'string',
        },
        'addCard:enable': {
          title: '#3 Add card',
          type: 'boolean',
          description: '[paymentInfoScreen.addCard]',
        },
        addCard: {
          type: 'string',
        },
        'screenTitle:enable': {
          title: '#4 Screen title',
          type: 'boolean',
          description: '[paymentInfoScreen.screenTitle]',
        },
        screenTitle: {
          type: 'string',
        },
        'confirmRemoveCard:enable': {
          title: '#5 Confirm remove card',
          type: 'boolean',
          description: '[paymentInfoScreen.confirmRemoveCard]',
        },
        confirmRemoveCard: {
          type: 'string',
        },
      },
    },
    IDVerificationScreen: {
      type: 'object',
      title: '#7 ID verification screen',
      properties: {
        'title:enable': {
          title: '#1 title',
          type: 'boolean',
          description: '[IDVerificationScreen.title]',
        },
        title: {
          type: 'string',
        },
        'verifyIDTitle:enable': {
          title: '#2 Verify ID title',
          type: 'boolean',
          description: '[IDVerificationScreen.verifyIDTitle]',
        },
        verifyIDTitle: {
          type: 'string',
        },
        'verifyDescription:enable': {
          title: '#3 Verify description',
          type: 'boolean',
          description: '[IDVerificationScreen.verifyDescription]',
        },
        verifyDescription: {
          type: 'string',
        },
        'safetyTitle:enable': {
          title: '#4 Safety title',
          type: 'boolean',
          description: '[IDVerificationScreen.safetyTitle]',
        },
        safetyTitle: {
          type: 'string',
        },
        'safetyDescription:enable': {
          title: '#5 Safety description',
          type: 'boolean',
          description: '[IDVerificationScreen.safetyDescription]',
        },
        safetyDescription: {
          type: 'string',
        },
        'securityTitle:enable': {
          title: '#6 Security title',
          type: 'boolean',
          description: '[IDVerificationScreen.securityTitle]',
        },
        securityTitle: {
          type: 'string',
        },
        'securityDescription:enable': {
          title: '#7 Security description',
          type: 'boolean',
          description: '[IDVerificationScreen.securityDescription]',
        },
        securityDescription: {
          type: 'string',
        },
        'startButton:enable': {
          title: '#8 Start button',
          type: 'boolean',
          description: '[IDVerificationScreen.startButton]',
        },
        startButton: {
          type: 'string',
        },
      },
    },
    waitingIDVerificationScreen: {
      type: 'object',
      title: '#8 Waiting ID verification screen',
      properties: {
        'checkResult:enable': {
          title: '#1 Check result',
          type: 'boolean',
          description: '[waitingIDVerificationScreen.checkResult]',
        },
        checkResult: {
          type: 'string',
        },
        'oneMinute:enable': {
          title: '#2 One minute',
          type: 'boolean',
          description: '[waitingIDVerificationScreen.oneMinute]',
        },
        oneMinute: {
          type: 'string',
        },
        'idVerificationFailed:enable': {
          title: '#3 ID verification failed',
          type: 'boolean',
          description: '[waitingIDVerificationScreen.idVerificationFailed]',
        },
        idVerificationFailed: {
          type: 'string',
        },
        'confirmReservation:enable': {
          title: '#4 Confirm reservation',
          type: 'boolean',
          description: '[waitingIDVerificationScreen.confirmReservation]',
        },
        confirmReservation: {
          type: 'string',
        },
        'startButton:enable': {
          title: '#5 Start button',
          type: 'boolean',
          description: '[waitingIDVerificationScreen.startButton]',
        },
        startButton: {
          type: 'string',
        },
      },
    },
    resetPasswordScreen: {
      type: 'object',
      title: '#9 Reset password screen',
      properties: {
        'passwordResetSuccessfully:enable': {
          title: '#1 Password reset successfully',
          type: 'boolean',
          description: '[resetPasswordScreen.passwordResetSuccessfully]',
        },
        passwordResetSuccessfully: {
          type: 'string',
        },
        'signinWithNewPassword:enable': {
          title: '#2 Sign in with new password',
          type: 'boolean',
          description: '[resetPasswordScreen.signinWithNewPassword]',
        },
        signinWithNewPassword: {
          type: 'string',
        },
        'passwordMismatch:enable': {
          title: '#3 Password mismatch',
          type: 'boolean',
          description: '[resetPasswordScreen.passwordMismatch]',
        },
        passwordMismatch: {
          type: 'string',
        },
        'newPassword:enable': {
          title: '#4 New password',
          type: 'boolean',
          description: '[resetPasswordScreen.newPassword]',
        },
        newPassword: {
          type: 'string',
        },
        'password:enable': {
          title: '#5 Password',
          type: 'boolean',
          description: '[resetPasswordScreen.password]',
        },
        password: {
          type: 'string',
        },
        'confirmPassword:enable': {
          title: '#6 Confirm password',
          type: 'boolean',
          description: '[resetPasswordScreen.confirmPassword]',
        },
        confirmPassword: {
          type: 'string',
        },
        'submitButton:enable': {
          title: '#7 Submit button',
          type: 'boolean',
          description: '[resetPasswordScreen.submitButton]',
        },
        submitButton: {
          type: 'string',
        },
      },
    },
    validateCodeScreen: {
      type: 'object',
      title: '#10 Validate code screen',
      properties: {
        'continue:enable': {
          title: '#1 Continue',
          type: 'boolean',
          description: '[validateCodeScreen.continue]',
        },
        continue: {
          type: 'string',
        },
        'verifyCode:enable': {
          title: '#2 Verify code',
          type: 'boolean',
          description: '[validateCodeScreen.verifyCode]',
        },
        verifyCode: {
          type: 'string',
        },
        'checkYourInboxLeft:enable': {
          title: '#3 Check your inbox left',
          type: 'boolean',
          description: '[validateCodeScreen.checkYourInboxLeft]',
        },
        checkYourInboxLeft: {
          type: 'string',
        },
        'checkYourInboxRight:enable': {
          title: '#4 Check your inbox right',
          type: 'boolean',
          description: '[validateCodeScreen.checkYourInboxRight]',
        },
        checkYourInboxRight: {
          type: 'string',
        },
        'youTheCodeAt:enable': {
          title: '#5 You the code at',
          type: 'boolean',
          description: '[validateCodeScreen.youTheCodeAt]',
        },
        youTheCodeAt: {
          type: 'string',
        },
        'resendCode:enable': {
          title: '#6 Resend code button',
          type: 'boolean',
          description: '[validateCodeScreen.resendCode]',
        },
        resendCode: {
          type: 'string',
        },
        'codeSent:enable': {
          title: '#7 Successfully resend code text',
          type: 'boolean',
          description: '[validateCodeScreen.codeSent]',
        },
        codeSent: {
          type: 'string',
        },
        'fallbackResendCodeError:enable': {
          title: '#8 Generic resend code error text',
          type: 'boolean',
          description: '[validateCodeScreen.fallbackResendCodeError]',
        },
        fallbackResendCodeError: {
          type: 'string',
        },
        'fallbackIncorrectCodeError:enable': {
          title: '#9 Generic incorrect code error text',
          type: 'boolean',
          description: '[validateCodeScreen.fallbackIncorrectCodeError]',
        },
        fallbackIncorrectCodeError: {
          type: 'string',
        },
      },
    },
    loginIDVerificationScreen: {
      type: 'object',
      title: '#11 Login ID verification screen',
      properties: {
        'title:enable': {
          title: '#1 Title',
          type: 'boolean',
          description: '[loginIDVerificationScreen.title]',
        },
        title: {
          type: 'string',
        },
        'startVerifyButton:enable': {
          title: '#2 Start verify button',
          type: 'boolean',
          description: '[loginIDVerificationScreen.startVerifyButton]',
        },
        startVerifyButton: {
          type: 'string',
        },
      },
    },
    forgotPasswordScreen: {
      type: 'object',
      title: '#12 Forgot password screen',
      properties: {
        'resetPasswordLink:enable': {
          title: '#1 Reset password link',
          type: 'boolean',
          description: '[forgotPasswordScreen.resetPasswordLink]',
        },
        resetPasswordLink: {
          type: 'string',
        },
        'pleaseCheck:enable': {
          title: '#2 Please check',
          type: 'boolean',
          description: '[forgotPasswordScreen.pleaseCheck]',
        },
        pleaseCheck: {
          type: 'string',
        },
        'followLink:enable': {
          title: '#3 Follow link',
          type: 'boolean',
          description: '[forgotPasswordScreen.followLink]',
        },
        followLink: {
          type: 'string',
        },
        'forgotPassword:enable': {
          title: '#4 Forgot password',
          type: 'boolean',
          description: '[forgotPasswordScreen.forgotPassword]',
        },
        forgotPassword: {
          type: 'string',
        },
        'sendYourPassword:enable': {
          title: '#5 Send your password',
          type: 'boolean',
          description: '[forgotPasswordScreen.sendYourPassword]',
        },
        sendYourPassword: {
          type: 'string',
        },
      },
    },
    loginEmailScreen: {
      type: 'object',
      title: '#13 Login email screen',
      properties: {
        'validEmail:enable': {
          title: '#1 Valid email',
          type: 'boolean',
          description: '[loginEmailScreen.validEmail]',
        },
        validEmail: {
          type: 'string',
        },
        'submitButton:enable': {
          title: '#2 Submit button',
          type: 'boolean',
          description: '[loginEmailScreen.submitButton]',
        },
        submitButton: {
          type: 'string',
        },
        'emailPlaceholder:enable': {
          title: '#3 Email placeholder',
          type: 'boolean',
          description: '[loginEmailScreen.emailPlaceholder]',
        },
        emailPlaceholder: {
          type: 'string',
        },
        'title:enable': {
          title: '#4 Title',
          type: 'boolean',
          description: '[loginEmailScreen.title]',
        },
        title: {
          type: 'string',
        },
        'description:enable': {
          title: '#5 Description',
          type: 'boolean',
          description: '[loginEmailScreen.description]',
        },
        description: {
          type: 'string',
        },
        'or:enable': {
          title: '#6 Or',
          type: 'boolean',
          description: '[loginEmailScreen.or]',
        },
        or: {
          type: 'string',
        },
      },
    },
    loginScreen: {
      type: 'object',
      title: '#14 Login screen',
      properties: {
        'title:enable': {
          title: '#1 title',
          type: 'boolean',
          description: '[loginScreen.title]',
        },
        title: {
          type: 'string',
        },
        'signin:enable': {
          title: '#2 Sign in',
          type: 'boolean',
          description: '[loginScreen.signin]',
        },
        signin: {
          type: 'string',
        },
        'email:enable': {
          title: '#3 Email',
          type: 'boolean',
          description: '[loginScreen.email]',
        },
        email: {
          type: 'string',
        },
        'phoneNumber:enable': {
          title: '#4 Phone number',
          type: 'boolean',
          description: '[loginScreen.phoneNumber]',
        },
        phoneNumber: {
          type: 'string',
        },
        'confirmationCode:enable': {
          title: '#5 Confirmation code',
          type: 'boolean',
          description: '[loginScreen.confirmationCode]',
        },
        confirmationCode: {
          type: 'string',
        },
        'nameAndDepartureDate:enable': {
          title: '#6 Name and Departure Date',
          type: 'boolean',
          description: '[loginScreen.nameAndDepartureDate]',
        },
        nameAndDepartureDate: {
          type: 'string',
        },
      },
    },
    profileScreen: {
      type: 'object',
      title: '#15 Profile screen',
      properties: {
        'screenTitle:enable': {
          title: '#1 Screen title',
          type: 'boolean',
          description: '[profileScreen.screenTitle]',
        },
        screenTitle: {
          type: 'string',
        },
        'supportSectionTitle:enable': {
          title: '#2 Support section title',
          type: 'boolean',
          description: '[profileScreen.supportSectionTitle]',
        },
        supportSectionTitle: {
          type: 'string',
        },
        'QATitle:enable': {
          title: '#3 QA Title',
          type: 'boolean',
          description: '[profileScreen.QATitle]',
        },
        QATitle: {
          type: 'string',
        },
        'feedback:enable': {
          title: '#4 Feedback',
          type: 'boolean',
          description: '[profileScreen.feedback]',
        },
        feedback: {
          type: 'string',
        },
        'tos:enable': {
          title: '#5 Tos',
          type: 'boolean',
          description: '[profileScreen.tos]',
        },
        tos: {
          type: 'string',
        },
        'language:enable': {
          title: '#6 Language',
          type: 'boolean',
          description: '[profileScreen.language]',
        },
        language: {
          type: 'string',
        },
        'logout:enable': {
          title: '#7 Logout',
          type: 'boolean',
          description: '[profileScreen.logout]',
        },
        logout: {
          type: 'string',
        },
        'logoutAlertTitle:enable': {
          title: '#8 Logout alert title',
          type: 'boolean',
          description: '[profileScreen.logoutAlertTitle]',
        },
        logoutAlertTitle: {
          type: 'string',
        },
        'areYouSureAlertText:enable': {
          title: '#9 Are you sure alert text',
          type: 'boolean',
          description: '[profileScreen.areYouSureAlertText]',
        },
        areYouSureAlertText: {
          type: 'string',
        },
        'cancelText:enable': {
          title: '#10 Cancel text',
          type: 'boolean',
          description: '[profileScreen.cancelText]',
        },
        cancelText: {
          type: 'string',
        },
        'accountSetting:enable': {
          title: '#11 Account setting',
          type: 'boolean',
          description: '[profileScreen.accountSetting]',
        },
        accountSetting: {
          type: 'string',
        },
        'personalInformation:enable': {
          title: '#12 Personal information',
          type: 'boolean',
          description: '[profileScreen.personalInformation]',
        },
        personalInformation: {
          type: 'string',
        },
        'payment:enable': {
          title: '#13 Payment',
          type: 'boolean',
          description: '[profileScreen.payment]',
        },
        payment: {
          type: 'string',
        },
        'verify:enable': {
          title: '#14 Verify',
          type: 'boolean',
          description: '[profileScreen.verify]',
        },
        verify: {
          type: 'string',
        },
        'addCorporateId:enable': {
          title: '#15 Add corporate ID',
          type: 'boolean',
          description: '[profileScreen.addCorporateId]',
        },
        addCorporateId: {
          type: 'string',
        },
      },
    },
    loginPasswordScreen: {
      type: 'object',
      title: '#16 Login password screen',
      properties: {
        'confirmPassword:enable': {
          title: '#1 Confirm password',
          type: 'boolean',
          description: '[loginPasswordScreen.confirmPassword]',
        },
        confirmPassword: {
          type: 'string',
        },
        'pleaseConfirm:enable': {
          title: '#2 Please confirm',
          type: 'boolean',
          description: '[loginPasswordScreen.pleaseConfirm]',
        },
        pleaseConfirm: {
          type: 'string',
        },
        'forgotPassword:enable': {
          title: '#3 Forgot password',
          type: 'boolean',
          description: '[loginPasswordScreen.forgotPassword]',
        },
        forgotPassword: {
          type: 'string',
        },
        'submit:enable': {
          title: '#4 Submit',
          type: 'boolean',
          description: '[loginPasswordScreen.submit]',
        },
        submit: {
          type: 'string',
        },
      },
    },
    paymentAuthorizationScreen: {
      type: 'object',
      title: '#17 Payment authorization screen (Mobile App & Web)',
      properties: {
        'mobileApp:label': {
          title: 'Mobile App Section',
          type: 'object',
          description: '[paymentAuthorizationScreen] Localization for mobile app',
          properties: {},
        },
        'internalServerError:enable': {
          title: '#1 Internal server error',
          type: 'boolean',
          description: '[paymentAuthorizationScreen.internalServerError]',
        },
        internalServerError: {
          type: 'string',
        },
        'onlyAuthorized:enable': {
          title: '#2 Only authorized',
          type: 'boolean',
          description: '[paymentAuthorizationScreen.onlyAuthorized]',
        },
        onlyAuthorized: {
          type: 'string',
        },
        'creditCardAuthorizeLeft:enable': {
          title: '#3 Credit card authorize left',
          type: 'boolean',
          description: '[paymentAuthorizationScreen.creditCardAuthorizeLeft]',
        },
        creditCardAuthorizeLeft: {
          type: 'string',
        },
        'creditCardAuthorizeRight:enable': {
          title: '#4 Credit card authorize right',
          type: 'boolean',
          description: '[paymentAuthorizationScreen.creditCardAuthorizeRight]',
        },
        creditCardAuthorizeRight: {
          type: 'string',
        },
        'screenTitle:enable': {
          title: '#5 Screen title',
          type: 'boolean',
          description: '[paymentAuthorizationScreen.screenTitle]',
        },
        screenTitle: {
          type: 'string',
        },
        'mobileWeb:label': {
          title: 'Mobile Web Section',
          type: 'object',
          description: '[paymentAuthorizationScreen] Localization for mobile web',
          properties: {},
        },
        'yourCardWillNotBeChargedText:enable': {
          title: '#1 Your Card Will Not Be Charged Text',
          type: 'boolean',
          description: '[paymentAuthorizationScreen.yourCardWillNotBeChargedText]',
        },
        yourCardWillNotBeChargedText: {
          type: 'string',
        },
        'notSupportPaymentProviderText:enable': {
          title: '#2 Not Support Payment Provider Text',
          type: 'boolean',
          description: '[paymentAuthorizationScreen.notSupportPaymentProviderText]',
        },
        notSupportPaymentProviderText: {
          type: 'string',
        },
        'pleaseProvideCreditCardText1:enable': {
          title: '#3 Please Provide Credit Card Text 1',
          type: 'boolean',
          description: '[paymentAuthorizationScreen.pleaseProvideCreditCardText1]',
        },
        pleaseProvideCreditCardText1: {
          type: 'string',
        },
        'pleaseProvideCreditCardText2:enable': {
          title: '#4 Please Provide Credit Card Text 2',
          type: 'boolean',
          description: '[paymentAuthorizationScreen.pleaseProvideCreditCardText2]',
        },
        pleaseProvideCreditCardText2: {
          type: 'string',
        },
        paymentDisabled: {
          type: 'object',
          title: '#5 Payment Disabled',
          properties: {
            'paymentDisabledText1:enable': {
              title: '#5.1 Payment Disabled Text 1',
              type: 'boolean',
              description: '[paymentAuthorizationScreen.paymentDisabled.paymentDisabledText1]',
            },
            paymentDisabledText1: {
              type: 'string',
            },
            'paymentDisabledText2:enable': {
              title: '#5.2 Payment Disabled Text 2',
              type: 'boolean',
              description: '[paymentAuthorizationScreen.paymentDisabled.paymentDisabledText2]',
            },
            paymentDisabledText2: {
              type: 'string',
            },
            'button:enable': {
              title: '#5.3 Button',
              type: 'boolean',
              description: '[paymentAuthorizationScreen.paymentDisabled.button]',
            },
            button: {
              type: 'string',
            },
          },
        },
      },
    },
    paymentFormScreen: {
      type: 'object',
      title: '#18 Payment form screen',
      properties: {
        'failedToAddPayment:enable': {
          title: '#1 Failed to add payment',
          type: 'boolean',
          description: '[paymentFormScreen.failedToAddPayment]',
        },
        failedToAddPayment: {
          type: 'string',
        },
      },
    },
    loginPhoneScreen: {
      type: 'object',
      title: '#19 Login phone screen',
      properties: {
        'enterValidPhoneNumber:enable': {
          title: '#1 Enter valid phone number',
          type: 'boolean',
          description: '[loginPhoneScreen.enterValidPhoneNumber]',
        },
        enterValidPhoneNumber: {
          type: 'string',
        },
        'codeSend:enable': {
          title: '#2 Code send',
          type: 'boolean',
          description: '[loginPhoneScreen.codeSend]',
        },
        codeSend: {
          type: 'string',
        },
        'title:enable': {
          title: '#3 Title',
          type: 'boolean',
          description: '[loginPhoneScreen.title]',
        },
        title: {
          type: 'string',
        },
        'submit:enable': {
          title: '#4 Submit',
          type: 'boolean',
          description: '[loginPhoneScreen.submit]',
        },
        submit: {
          type: 'string',
        },
        'phonePlaceholder:enable': {
          title: '#5 Phone placeholder',
          type: 'boolean',
          description: '[loginPhoneScreen.phonePlaceholder]',
        },
        phonePlaceholder: {
          type: 'string',
        },
      },
    },
    confirmationNumberScreen: {
      type: 'object',
      title: '#20 Confirmation number screen',
      properties: {
        'confirmationNumber:enable': {
          title: '#1 confirmationNumber',
          type: 'boolean',
          description: '[confirmationNumberScreen.confirmationNumber]',
        },
        confirmationNumber: {
          type: 'string',
        },
        'emptyData:enable': {
          title: '#2 Empty data',
          type: 'boolean',
          description: '[confirmationNumberScreen.emptyData]',
        },
        emptyData: {
          type: 'string',
        },
        'title:enable': {
          title: '#3 Title',
          type: 'boolean',
          description: '[confirmationNumberScreen.title]',
        },
        title: {
          type: 'string',
        },
        'signinContinue:enable': {
          title: '#4 Sign in continue',
          type: 'boolean',
          description: '[confirmationNumberScreen.signinContinue]',
        },
        signinContinue: {
          type: 'string',
        },
        'enterConfirmNumber:enable': {
          title: '#5 Enter confirm number',
          type: 'boolean',
          description: '[confirmationNumberScreen.enterConfirmNumber]',
        },
        enterConfirmNumber: {
          type: 'string',
        },
        'lastName:enable': {
          title: '#6 Last name',
          type: 'boolean',
          description: '[confirmationNumberScreen.lastName]',
        },
        lastName: {
          type: 'string',
        },
        'continue:enable': {
          title: '#7 Continue',
          type: 'boolean',
          description: '[confirmationNumberScreen.continue]',
        },
        continue: {
          type: 'string',
        },
      },
    },
    currentBookingScreen: {
      type: 'object',
      title: '#21 Current Booking Screen (Mobile Web)',
      description: 'This is applicable for mobile web only',
      properties: {
        'title:enable': {
          title: '#1 Title',
          type: 'boolean',
          description: '[currentBookingScreen.title]',
        },
        title: {
          type: 'string',
        },
        label: {
          type: 'object',
          title: '#2 Label',
          properties: {
            'hotelName:enable': {
              title: '#2.1 Hotel Name',
              type: 'boolean',
              description: '[currentBookingScreen.label.hotelName]',
            },
            hotelName: {
              type: 'string',
            },
            'arrivalDetails:enable': {
              title: '#2.2 Arrival Details',
              type: 'boolean',
              description: '[currentBookingScreen.label.arrivalDetails]',
            },
            arrivalDetails: {
              type: 'string',
            },
            'checkIn:enable': {
              title: '#2.3 Check-in',
              type: 'boolean',
              description: '[currentBookingScreen.label.checkIn]',
            },
            checkIn: {
              type: 'string',
            },
            'checkOut:enable': {
              title: '#2.4 Check-out',
              type: 'boolean',
              description: '[currentBookingScreen.label.checkOut]',
            },
            checkOut: {
              type: 'string',
            },
            'roomNumber:enable': {
              title: '#2.5 Room number',
              type: 'boolean',
              description: '[currentBookingScreen.label.roomNumber]',
            },
            roomNumber: {
              type: 'string',
            },
          },
        },
        'instructionTextHtml:enable': {
          title: '#3 Instruction Text HTML',
          type: 'boolean',
          description: '[currentBookingScreen.instructionTextHtml]',
        },
        instructionTextHtml: {
          type: 'string',
        },
        'checkInSuccessTitle:enable': {
          title: '#4 Check-in Success Title',
          type: 'boolean',
          description: '[currentBookingScreen.checkInSuccessTitle]',
        },
        checkInSuccessTitle: {
          type: 'string',
        },
        'checkInSuccessMessage:enable': {
          title: '#5 Check-in Success Message',
          type: 'boolean',
          description: '[currentBookingScreen.checkInSuccessMessage]',
        },
        checkInSuccessMessage: {
          type: 'string',
        },
        incompleteRequirements: {
          type: 'object',
          title: '#6 Incomplete Requirements',
          properties: {
            'title:enable': {
              title: '#6.1 Title',
              type: 'boolean',
              description: '[currentBookingScreen.incompleteRequirements.title]',
            },
            title: {
              type: 'string',
            },
            'description:enable': {
              title: '#6.2 Description',
              type: 'boolean',
              description: '[currentBookingScreen.incompleteRequirements.description]',
            },
            description: {
              type: 'string',
            },
            'button:enable': {
              title: '#6.3 Button',
              type: 'boolean',
              description: '[currentBookingScreen.incompleteRequirements.button]',
            },
            button: {
              type: 'string',
            },
          },
        },
        'disabledCheckInText:enable': {
          title: '#7 Disabled Check-in Text',
          type: 'boolean',
          description: '[currentBookingScreen.disabledCheckInText]',
        },
        disabledCheckInText: {
          type: 'string',
        },
        'checkInButtonText:enable': {
          title: '#8 Check-in Button Text',
          type: 'boolean',
          description: '[currentBookingScreen.checkInButtonText]',
        },
        checkInButtonText: {
          type: 'string',
        },
        'nextStepButtonText:enable': {
          title: '#9 Next Step Button Text',
          type: 'boolean',
          description: '[currentBookingScreen.nextStepButtonText]',
        },
        nextStepButtonText: {
          type: 'string',
        },
        errors: {
          type: 'object',
          title: '#10 Errors',
          properties: {
            idVerification: {
              type: 'object',
              title: '#10.1 ID Verification',
              properties: {
                AGE_VERIFICATION_FAILED: {
                  type: 'object',
                  title: '#10.1.1 Age verification failed',
                  properties: {
                    'header:enable': {
                      title: '#10.1.1.1 Header',
                      type: 'boolean',
                      description:
                        '[currentBookingScreen.errors.idVerification.AGE_VERIFICATION_FAILED.header]',
                    },
                    header: {
                      type: 'string',
                    },
                    'subheader:enable': {
                      title: '#10.1.1.2 Sub header',
                      type: 'boolean',
                      description:
                        '[currentBookingScreen.errors.idVerification.AGE_VERIFICATION_FAILED.subheader]',
                    },
                    subheader: {
                      type: 'string',
                    },
                  },
                },
                ID_VERIFICATION_DATA_MISSMATCH: {
                  type: 'object',
                  title: '#10.1.2 ID verification data mismatch',
                  properties: {
                    'header:enable': {
                      title: '#10.1.2.1 Header',
                      type: 'boolean',
                      description:
                        '[currentBookingScreen.errors.idVerification.ID_VERIFICATION_DATA_MISSMATCH.header]',
                    },
                    header: {
                      type: 'string',
                    },
                    'subheader:enable': {
                      title: '#10.1.2.2 Sub header',
                      type: 'boolean',
                      description:
                        '[currentBookingScreen.errors.idVerification.ID_VERIFICATION_DATA_MISSMATCH.subheader]',
                    },
                    subheader: {
                      type: 'string',
                    },
                  },
                },
                VENDOR_INTEGRATION_FAILED: {
                  type: 'object',
                  title: '#10.1.3 Vendor integration failed',
                  properties: {
                    'header:enable': {
                      title: '#10.1.3.1 Header',
                      type: 'boolean',
                      description:
                        '[currentBookingScreen.errors.idVerification.VENDOR_INTEGRATION_FAILED.header]',
                    },
                    header: {
                      type: 'string',
                    },
                    'subheader:enable': {
                      title: '#10.1.3.2 Sub header',
                      type: 'boolean',
                      description:
                        '[currentBookingScreen.errors.idVerification.VENDOR_INTEGRATION_FAILED.subheader]',
                    },
                    subheader: {
                      type: 'string',
                    },
                  },
                },
                ID_VERIFICATION_FAILED: {
                  type: 'object',
                  title: '#10.1.4 ID verification failed',
                  properties: {
                    'header:enable': {
                      title: '#10.1.4.1 Header',
                      type: 'boolean',
                      description:
                        '[currentBookingScreen.errors.idVerification.ID_VERIFICATION_FAILED.header]',
                    },
                    header: {
                      type: 'string',
                    },
                    'subheader:enable': {
                      title: '#10.1.4.2 Sub header',
                      type: 'boolean',
                      description:
                        '[currentBookingScreen.errors.idVerification.ID_VERIFICATION_FAILED.subheader]',
                    },
                    subheader: {
                      type: 'string',
                    },
                  },
                },
                SERVER_ERROR: {
                  type: 'object',
                  title: '#10.1.5 Server error',
                  properties: {
                    'header:enable': {
                      title: '#10.1.5.1 Header',
                      type: 'boolean',
                      description: '[currentBookingScreen.idVerification.SERVER_ERROR.header]',
                    },
                    header: {
                      type: 'string',
                    },
                    'subheader:enable': {
                      title: '#10.1.5.2 Sub header',
                      type: 'boolean',
                      description: '[currentBookingScreen.idVerification.SERVER_ERROR.subheader]',
                    },
                    subheader: {
                      type: 'string',
                    },
                  },
                },
              },
            },
          },
        },
        'getQrCodeButtonText:enable': {
          title: '#11 Get QR code button text',
          type: 'boolean',
          description: '[currentBookingScreen.getQrCodeButtonText]',
        },
        getQrCodeButtonText: {
          type: 'string',
        },
      },
    },
    idVerificationInitScreen: {
      type: 'object',
      title: '#22 ID Verification Initialize Screen (Mobile Web)',
      description: 'This is applicable for mobile web only',
      properties: {
        'title:enable': {
          title: '#1 Title',
          type: 'boolean',
          description: '[idVerificationInitScreen.title]',
        },
        title: {
          type: 'string',
        },
        label: {
          type: 'object',
          title: '#2 Label',
          properties: {
            'passport:enable': {
              title: '#2.1 Passport',
              type: 'boolean',
              description: '[idVerificationInitScreen.label.passport]',
            },
            passport: {
              type: 'string',
            },
            'idCard:enable': {
              title: '#2.2 ID Card',
              type: 'boolean',
              description: '[idVerificationInitScreen.label.idCard]',
            },
            idCard: {
              type: 'string',
            },
            'driverLicense:enable': {
              title: '#2.3 Driver License',
              type: 'boolean',
              description: '[idVerificationInitScreen.label.driverLicense]',
            },
            driverLicense: {
              type: 'string',
            },
            'change:enable': {
              title: '#2.4 Change',
              type: 'boolean',
              description: '[idVerificationInitScreen.label.change]',
            },
            change: {
              type: 'string',
            },
            'document:enable': {
              title: '#2.5 Document',
              type: 'boolean',
              description: '[idVerificationInitScreen.label.document]',
            },
            document: {
              type: 'string',
            },
          },
        },
        'setCountryInstructionText1:enable': {
          title: '#3 Set Country Instruction Text 1',
          type: 'boolean',
          description: '[idVerificationInitScreen.setCountryInstructionText1]',
        },
        setCountryInstructionText1: {
          type: 'string',
        },
        'setCountryInstructionText2:enable': {
          title: '#4 Set Country Instruction Text 2',
          type: 'boolean',
          description: '[idVerificationInitScreen.setCountryInstructionText2]',
        },
        setCountryInstructionText2: {
          type: 'string',
        },
        consent: {
          type: 'object',
          title: '#5 Consent',
          properties: {
            'title:enable': {
              title: '#5.1 Consent title',
              type: 'boolean',
              description: '[idVerificationInitScreen.consent.title]',
            },
            title: {
              type: 'string',
            },
            'description:enable': {
              title: '#5.2 Select Country Picker text color',
              type: 'boolean',
              description: '[idVerificationInitScreen.consent.description]',
            },
            description: {
              type: 'string',
            },
            'privacyPolicy:enable': {
              title: '#5.3 Consent privacy policy text title',
              type: 'boolean',
              description: '[idVerificationInitScreen.consent.privacyPolicy]',
            },
            privacyPolicy: {
              type: 'string',
            },
            'buttonText:enable': {
              title: '#5.4 Consent popup button text',
              type: 'boolean',
              description: '[idVerificationInitScreen.consent.buttonText]',
            },
            buttonText: {
              type: 'string',
            },
          },
        },
      },
    },
    idVerificationScanDocumentScreen: {
      type: 'object',
      title: '#23 ID Verification Scan Document Screen (Mobile Web)',
      description: 'This is applicable for mobile web only',
      properties: {
        'title:enable': {
          title: '#1 Title',
          type: 'boolean',
          description: '[idVerificationScanDocumentScreen.title]',
        },
        title: {
          type: 'string',
        },
        'switchCamera:enable': {
          title: '#2 Switch Camera',
          type: 'boolean',
          description: '[idVerificationScanDocumentScreen.switchCamera]',
        },
        switchCamera: {
          type: 'string',
        },
        'capturePhoto:enable': {
          title: '#3 Capture Photo',
          type: 'boolean',
          description: '[idVerificationScanDocumentScreen.capturePhoto]',
        },
        capturePhoto: {
          type: 'string',
        },
        idCard: {
          type: 'object',
          title: '#4 ID Card',
          properties: {
            front: {
              type: 'object',
              title: '#4.1 Front',
              properties: {
                'title:enable': {
                  title: '#4.1.1 Title',
                  type: 'boolean',
                  description: '[idVerificationScanDocumentScreen.idCard.front.title]',
                },
                title: {
                  type: 'string',
                },
                'description:enable': {
                  title: '#4.1.2 Description',
                  type: 'boolean',
                  description: '[idVerificationScanDocumentScreen.idCard.front.description]',
                },
                description: {
                  type: 'string',
                },
                'buttonTitle:enable': {
                  title: '#4.1.3 Button Title',
                  type: 'boolean',
                  description: '[idVerificationScanDocumentScreen.idCard.front.buttonTitle]',
                },
                buttonTitle: {
                  type: 'string',
                },
              },
            },
            back: {
              type: 'object',
              title: '#4.2 Back',
              properties: {
                'title:enable': {
                  title: '#4.2.1 Title',
                  type: 'boolean',
                  description: '[idVerificationScanDocumentScreen.idCard.back.title]',
                },
                title: {
                  type: 'string',
                },
                'description:enable': {
                  title: '#4.2.2 Description',
                  type: 'boolean',
                  description: '[idVerificationScanDocumentScreen.idCard.back.description]',
                },
                description: {
                  type: 'string',
                },
                'buttonTitle:enable': {
                  title: '#4.2.3 Button Title',
                  type: 'boolean',
                  description: '[idVerificationScanDocumentScreen.idCard.back.buttonTitle]',
                },
                buttonTitle: {
                  type: 'string',
                },
              },
            },
            face: {
              type: 'object',
              title: '#4.3 Face',
              properties: {
                'title:enable': {
                  title: '#4.3.1 Title',
                  type: 'boolean',
                  description: '[idVerificationScanDocumentScreen.idCard.face.title]',
                },
                title: {
                  type: 'string',
                },
                'description:enable': {
                  title: '#4.3.2 Description',
                  type: 'boolean',
                  description: '[idVerificationScanDocumentScreen.idCard.face.description]',
                },
                description: {
                  type: 'string',
                },
                'buttonTitle:enable': {
                  title: '#4.3.3 Button Title',
                  type: 'boolean',
                  description: '[idVerificationScanDocumentScreen.idCard.face.buttonTitle]',
                },
                buttonTitle: {
                  type: 'string',
                },
              },
            },
          },
        },
        passport: {
          type: 'object',
          title: '#5 Passport',
          properties: {
            front: {
              type: 'object',
              title: '#5.1 Front',
              properties: {
                'title:enable': {
                  title: '#5.1.1 Title',
                  type: 'boolean',
                  description: '[idVerificationScanDocumentScreen.passport.front.title]',
                },
                title: {
                  type: 'string',
                },
                'description:enable': {
                  title: '#5.1.2 Description',
                  type: 'boolean',
                  description: '[idVerificationScanDocumentScreen.passport.front.description]',
                },
                description: {
                  type: 'string',
                },
                'buttonTitle:enable': {
                  title: '#5.1.3 Button Title',
                  type: 'boolean',
                  description: '[idVerificationScanDocumentScreen.passport.front.buttonTitle]',
                },
                buttonTitle: {
                  type: 'string',
                },
              },
            },
            face: {
              type: 'object',
              title: '#5.2 Face',
              properties: {
                'title:enable': {
                  title: '#5.2.1 Title',
                  type: 'boolean',
                  description: '[idVerificationScanDocumentScreen.passport.face.title]',
                },
                title: {
                  type: 'string',
                },
                'description:enable': {
                  title: '#5.2.2 Description',
                  type: 'boolean',
                  description: '[idVerificationScanDocumentScreen.passport.face.description]',
                },
                description: {
                  type: 'string',
                },
                'buttonTitle:enable': {
                  title: '#5.2.3 Button Title',
                  type: 'boolean',
                  description: '[idVerificationScanDocumentScreen.passport.face.buttonTitle]',
                },
                buttonTitle: {
                  type: 'string',
                },
              },
            },
          },
        },
        driverLicense: {
          type: 'object',
          title: '#6 Driver License',
          properties: {
            front: {
              type: 'object',
              title: '#6.1 Front',
              properties: {
                'title:enable': {
                  title: '#6.1.1 Title',
                  type: 'boolean',
                  description: '[idVerificationScanDocumentScreen.driverLicense.front.title]',
                },
                title: {
                  type: 'string',
                },
                'description:enable': {
                  title: '#6.1.2 Description',
                  type: 'boolean',
                  description: '[idVerificationScanDocumentScreen.driverLicense.front.description]',
                },
                description: {
                  type: 'string',
                },
                'buttonTitle:enable': {
                  title: '#6.1.3 Button Title',
                  type: 'boolean',
                  description: '[idVerificationScanDocumentScreen.driverLicense.front.buttonTitle]',
                },
                buttonTitle: {
                  type: 'string',
                },
              },
            },
            back: {
              type: 'object',
              title: '#6.2 Back',
              properties: {
                'title:enable': {
                  title: '#6.2.1 Title',
                  type: 'boolean',
                  description: '[idVerificationScanDocumentScreen.driverLicense.back.title]',
                },
                title: {
                  type: 'string',
                },
                'description:enable': {
                  title: '#6.2.2 Description',
                  type: 'boolean',
                  description: '[idVerificationScanDocumentScreen.driverLicense.back.description]',
                },
                description: {
                  type: 'string',
                },
                'buttonTitle:enable': {
                  title: '#6.2.3 Button Title',
                  type: 'boolean',
                  description: '[idVerificationScanDocumentScreen.driverLicense.back.buttonTitle]',
                },
                buttonTitle: {
                  type: 'string',
                },
              },
            },
            face: {
              type: 'object',
              title: '#6.3 Face',
              properties: {
                'title:enable': {
                  title: '#6.3.1 Title',
                  type: 'boolean',
                  description: '[idVerificationScanDocumentScreen.driverLicense.face.title]',
                },
                title: {
                  type: 'string',
                },
                'description:enable': {
                  title: '#6.3.2 Description',
                  type: 'boolean',
                  description: '[idVerificationScanDocumentScreen.driverLicense.face.description]',
                },
                description: {
                  type: 'string',
                },
                'buttonTitle:enable': {
                  title: '#6.3.3 Button Title',
                  type: 'boolean',
                  description: '[idVerificationScanDocumentScreen.driverLicense.face.buttonTitle]',
                },
                buttonTitle: {
                  type: 'string',
                },
              },
            },
          },
        },
        'frontOf:enable': {
          title: '#7 Front of',
          type: 'boolean',
          description: '[idVerificationScanDocumentScreen.frontOf]',
        },
        frontOf: {
          type: 'string',
        },
        'backOf:enable': {
          title: '#8 Back of',
          type: 'boolean',
          description: '[idVerificationScanDocumentScreen.backOf]',
        },
        backOf: {
          type: 'string',
        },
        'faceCapture:enable': {
          title: '#9 Face capture',
          type: 'boolean',
          description: '[idVerificationScanDocumentScreen.faceCapture]',
        },
        faceCapture: {
          type: 'string',
        },
        'passportText:enable': {
          title: '#10 Passport text',
          type: 'boolean',
          description: '[idVerificationScanDocumentScreen.passportText]',
        },
        passportText: {
          type: 'string',
        },
        'idCardText:enable': {
          title: '#11 ID card text',
          type: 'boolean',
          description: '[idVerificationScanDocumentScreen.idCardText]',
        },
        idCardText: {
          type: 'string',
        },
        'driverLicenseText:enable': {
          title: '#12 Driver license text',
          type: 'boolean',
          description: '[idVerificationScanDocumentScreen.driverLicenseText]',
        },
        driverLicenseText: {
          type: 'string',
        },
        'document:enable': {
          title: '#13 Document',
          type: 'boolean',
          description: '[idVerificationScanDocumentScreen.document]',
        },
        document: {
          type: 'string',
        },
      },
    },
    idVerificationResultScreen: {
      type: 'object',
      title: '#24 ID Verification Result Screen (Mobile Web)',
      description: 'This is applicable for mobile web only',
      properties: {
        waitingIdVerificationResult: {
          type: 'object',
          title: '#1 Waiting ID Verification Result',
          properties: {
            'title:enable': {
              title: '#1.1 Title',
              type: 'boolean',
              description: '[idVerificationResultScreen.waitingIdVerificationResult.title]',
            },
            title: {
              type: 'string',
            },
            'description:enable': {
              title: '#1.2 Description',
              type: 'boolean',
              description: '[idVerificationResultScreen.waitingIdVerificationResult.description]',
            },
            description: {
              type: 'string',
            },
          },
        },
        idVerificationFailed: {
          type: 'object',
          title: '#2 ID Verification Failed',
          properties: {
            'title:enable': {
              title: '#2.1 Title',
              type: 'boolean',
              description: '[idVerificationResultScreen.idVerificationFailed.title]',
            },
            title: {
              type: 'string',
            },
            'description:enable': {
              title: '#2.2 Description',
              type: 'boolean',
              description: '[idVerificationResultScreen.idVerificationFailed.description]',
            },
            description: {
              type: 'string',
            },
          },
        },
        idVerificationSuccess: {
          type: 'object',
          title: '#25 ID Verification Success (Mobile Web)',
          description: 'This is applicable for mobile web only',
          properties: {
            'title:enable': {
              title: '#3.1 Title',
              type: 'boolean',
              description: '[idVerificationResultScreen.idVerificationSuccess.title]',
            },
            title: {
              type: 'string',
            },
            'description:enable': {
              title: '#3.2 Description',
              type: 'boolean',
              description: '[idVerificationResultScreen.idVerificationSuccess.description]',
            },
            description: {
              type: 'string',
            },
            'button:enable': {
              title: '#3.3 Button',
              type: 'boolean',
              description: '[idVerificationResultScreen.idVerificationSuccess.button]',
            },
            button: {
              type: 'string',
            },
          },
        },
      },
    },
    paymentStatusScreen: {
      type: 'object',
      title: '#25 Payment Status Screen (Mobile Web)',
      description: 'This is applicable for mobile web only',
      properties: {
        'title:enable': {
          title: '#1 Title',
          type: 'boolean',
          description: '[paymentStatusScreen.title]',
        },
        title: {
          type: 'string',
        },
        'instructionText1:enable': {
          title: '#2 Instruction Text 1',
          type: 'boolean',
          description: '[paymentStatusScreen.instructionText1]',
        },
        instructionText1: {
          type: 'string',
        },
        'instructionText2:enable': {
          title: '#3 Instruction Text 2',
          type: 'boolean',
          description: '[paymentStatusScreen.instructionText2]',
        },
        instructionText2: {
          type: 'string',
        },
        label: {
          type: 'object',
          title: '#4 Label',
          properties: {
            'weAccept:enable': {
              title: '#4.1 We Accept',
              type: 'boolean',
              description: '[paymentStatusScreen.label.weAccept]',
            },
            weAccept: {
              type: 'string',
            },
          },
        },
        success: {
          type: 'object',
          title: '#5 Success',
          properties: {
            'title:enable': {
              title: '#5.1 Title',
              type: 'boolean',
              description: '[paymentStatusScreen.success.title]',
            },
            title: {
              type: 'string',
            },
            'description:enable': {
              title: '#5.2 Description',
              type: 'boolean',
              description: '[paymentStatusScreen.success.description]',
            },
            description: {
              type: 'string',
            },
            'button:enable': {
              title: '#5.3 Button',
              type: 'boolean',
              description: '[paymentStatusScreen.success.button]',
            },
            button: {
              type: 'string',
            },
          },
        },
        fail: {
          type: 'object',
          title: '#6 Fail',
          properties: {
            'title:enable': {
              title: '#6.1 Title',
              type: 'boolean',
              description: '[paymentStatusScreen.fail.title]',
            },
            title: {
              type: 'string',
            },
            'description:enable': {
              title: '#6.2 Description',
              type: 'boolean',
              description: '[paymentStatusScreen.fail.description]',
            },
            description: {
              type: 'string',
            },
            'button:enable': {
              title: '#6.3 Button',
              type: 'boolean',
              description: '[paymentStatusScreen.fail.button]',
            },
            button: {
              type: 'string',
            },
          },
        },
        paymentDisabled: {
          type: 'object',
          title: '#7 Payment Disabled',
          properties: {
            'instructionText1:enable': {
              title: '#7.1 Instruction Text 1',
              type: 'boolean',
              description: '[paymentStatusScreen.paymentDisabled.instructionText1]',
            },
            instructionText1: {
              type: 'string',
            },
            'instructionText2:enable': {
              title: '#7.2 Instruction Text 2',
              type: 'boolean',
              description: '[paymentStatusScreen.paymentDisabled.instructionText2]',
            },
            instructionText2: {
              type: 'string',
            },
            'button:enable': {
              title: '#7.3 Button',
              type: 'boolean',
              description: '[paymentStatusScreen.paymentDisabled.button]',
            },
            button: {
              type: 'string',
            },
          },
        },
      },
    },
    paymentAuthorizationSuccessScreen: {
      type: 'object',
      title: '#26 Payment Authorization Success Screen (Mobile Web)',
      description: 'This is applicable for mobile web only',
      properties: {
        'title:enable': {
          title: '#1 Title',
          type: 'boolean',
          description: '[paymentAuthorizationSuccessScreen.title]',
        },
        title: {
          type: 'string',
        },
        'description:enable': {
          title: '#2 Description',
          type: 'boolean',
          description: '[paymentAuthorizationSuccessScreen.description]',
        },
        description: {
          type: 'string',
        },
        'button:enable': {
          title: '#3 Button',
          type: 'boolean',
          description: '[paymentAuthorizationSuccessScreen.button]',
        },
        button: {
          type: 'string',
        },
      },
    },
    paymentAuthorizationErrorScreen: {
      type: 'object',
      title: '#27 Payment Authorization Error Screen (Mobile Web)',
      description: 'This is applicable for mobile web only',
      properties: {
        'title:enable': {
          title: '#1 Title',
          type: 'boolean',
          description: '[paymentAuthorizationErrorScreen.title]',
        },
        title: {
          type: 'string',
        },
        'description:enable': {
          title: '#2 Description',
          type: 'boolean',
          description: '[paymentAuthorizationErrorScreen.description]',
        },
        description: {
          type: 'string',
        },
        'button:enable': {
          title: '#3 Button',
          type: 'boolean',
          description: '[paymentAuthorizationErrorScreen.button]',
        },
        button: {
          type: 'string',
        },
      },
    },
    termsAndConditionsScreen: {
      type: 'object',
      title: '#28 Terms And Conditions Screen (Mobile Web)',
      description: 'This is applicable for mobile web only',
      properties: {
        'title:enable': {
          title: '#1 Title',
          type: 'boolean',
          description: '[termsAndConditionsScreen.title]',
        },
        title: {
          type: 'string',
        },
        label: {
          type: 'object',
          title: '#2 Label',
          properties: {
            'signature:enable': {
              title: '#2.1 Signature',
              type: 'boolean',
              description: '[termsAndConditionsScreen.label.signature]',
            },
            signature: {
              type: 'string',
            },
          },
        },
        success: {
          type: 'object',
          title: '#3 Success',
          properties: {
            'title:enable': {
              title: '#3.1 Title',
              type: 'boolean',
              description: '[termsAndConditionsScreen.success.title]',
            },
            title: {
              type: 'string',
            },
            'description:enable': {
              title: '#3.2 Description',
              type: 'boolean',
              description: '[termsAndConditionsScreen.success.description]',
            },
            description: {
              type: 'string',
            },
            'button:enable': {
              title: '#3.3 Button',
              type: 'boolean',
              description: '[termsAndConditionsScreen.success.button]',
            },
            button: {
              type: 'string',
            },
          },
        },
        fail: {
          type: 'object',
          title: '#4 Fail',
          properties: {
            'title:enable': {
              title: '#4.1 Title',
              type: 'boolean',
              description: '[termsAndConditionsScreen.fail.title]',
            },
            title: {
              type: 'string',
            },
            'description:enable': {
              title: '#4.2 Description',
              type: 'boolean',
              description: '[termsAndConditionsScreen.fail.description]',
            },
            description: {
              type: 'string',
            },
            'button:enable': {
              title: '#4.3 Button',
              type: 'boolean',
              description: '[termsAndConditionsScreen.fail.button]',
            },
            button: {
              type: 'string',
            },
          },
        },
      },
    },
    successScreen: {
      type: 'object',
      title: '#29 Success Screen (Mobile Web)',
      description: 'This is applicable for mobile web only',
      properties: {
        'title:enable': {
          title: '#1 Title',
          type: 'boolean',
          description: '[successScreen.title]',
        },
        title: {
          type: 'string',
        },
        'descriptionHtml:enable': {
          title: '#2 Description HTML',
          type: 'boolean',
          description: '[successScreen.descriptionHtml]',
        },
        descriptionHtml: {
          type: 'string',
        },
      },
    },
    userDetailsScreen: {
      type: 'object',
      title: '#30 User Details Screen (Mobile Web)',
      description: 'This is applicable for mobile web only',
      properties: {
        'guests:enable': {
          title: '#1 Guests',
          type: 'boolean',
          description: '[userDetailsScreen.guests]',
        },
        guests: {
          type: 'string',
        },
        'nameOnReservation:enable': {
          title: '#2 Name On Reservation',
          type: 'boolean',
          description: '[userDetailsScreen.nameOnReservation]',
        },
        nameOnReservation: {
          type: 'string',
        },
        'otherGuests:enable': {
          title: '#3 Other Guests',
          type: 'boolean',
          description: '[userDetailsScreen.otherGuests]',
        },
        otherGuests: {
          type: 'string',
        },
        header: {
          type: 'object',
          title: '#4 Header',
          properties: {
            'title:enable': {
              title: '#4.1 Title',
              type: 'boolean',
              description: '[userDetailsScreen.header.title]',
            },
            title: {
              type: 'string',
            },
            'description:enable': {
              title: '#4.2 Description',
              type: 'boolean',
              description: '[userDetailsScreen.header.description]',
            },
            description: {
              type: 'string',
            },
          },
        },
        'noResourceFound:enable': {
          title: '#5 No Resource Found',
          type: 'boolean',
          description: '[userDetailsScreen.noResourceFound]',
        },
        noResourceFound: {
          type: 'string',
        },
        'footerButtonText:enable': {
          title: '#6 Footer Button Text',
          type: 'boolean',
          description: '[userDetailsScreen.footerButtonText]',
        },
        footerButtonText: {
          type: 'string',
        },
        'edit:enable': {
          title: '#7 Edit',
          type: 'boolean',
          description: '[userDetailsScreen.edit]',
        },
        edit: {
          type: 'string',
        },
        'email:enable': {
          title: '#8 Email',
          type: 'boolean',
          description: '[userDetailsScreen.email]',
        },
        email: {
          type: 'string',
        },
        'phoneNumber:enable': {
          title: '#9 Phone number',
          type: 'boolean',
          description: '[userDetailsScreen.phoneNumber]',
        },
        phoneNumber: {
          type: 'string',
        },
        'save:enable': {
          title: '#10 Save',
          type: 'boolean',
          description: '[userDetailsScreen.save]',
        },
        save: {
          type: 'string',
        },
      },
    },
    roomNotReadyScreen: {
      type: 'object',
      title: '#31 Room Not Ready Screen (Mobile Web)',
      description: 'This is applicable for mobile web only',
      properties: {
        'title:enable': {
          title: '#1 Title',
          type: 'boolean',
          description: '[roomNotReadyScreen.title]',
        },
        title: {
          type: 'string',
        },
        'descriptionHtml:enable': {
          title: '#2 Description HTML',
          type: 'boolean',
          description: '[roomNotReadyScreen.descriptionHtml]',
        },
        descriptionHtml: {
          type: 'string',
        },
        'button:enable': {
          title: '#3 Button text',
          type: 'boolean',
          description: '[roomNotReadyScreen.button]',
        },
        button: {
          type: 'string',
        },
      },
    },
    packagesScreen: {
      type: 'object',
      title: '#32 Packages Screen (Mobile Web)',
      description: 'This is applicable for mobile web only',
      properties: {
        'prebookHeader:enable': {
          title: '#1 Pre-booked Header',
          type: 'boolean',
          description: '[packagesScreen.prebookHeader]',
        },
        prebookHeader: {
          type: 'string',
        },
        'skipButton:enable': {
          title: '#2 Skip Button',
          type: 'boolean',
          description: '[packagesScreen.skipButton]',
        },
        skipButton: {
          type: 'string',
        },
        'viewCart:enable': {
          title: '#3 View Cart',
          type: 'boolean',
          description: '[packagesScreen.viewCart]',
        },
        viewCart: {
          type: 'string',
        },
        'singlePrebook:enable': {
          title: '#4 Single Pre-booked',
          type: 'boolean',
          description: '[packagesScreen.singlePrebook]',
        },
        singlePrebook: {
          type: 'string',
        },
        'emptyPrebook:enable': {
          title: '#5 Empty Pre-booked',
          type: 'boolean',
          description: '[packagesScreen.emptyPrebook]',
        },
        emptyPrebook: {
          type: 'string',
        },
        'subtitle:enable': {
          title: '#6 Subtitle',
          type: 'boolean',
          description: '[packagesScreen.subtitle]',
        },
        subtitle: {
          type: 'string',
        },
        'seePackageText:enable': {
          title: '#7 See Package Text',
          type: 'boolean',
          description: '[packagesScreen.seePackageText]',
        },
        seePackageText: {
          type: 'string',
        },
        'booked:enable': {
          title: '#8 Booked',
          type: 'boolean',
          description: '[packagesScreen.booked]',
        },
        booked: {
          type: 'string',
        },
        'add:enable': {
          title: '#9 Add',
          type: 'boolean',
          description: '[packagesScreen.add]',
        },
        add: {
          type: 'string',
        },
        'addModal:enable': {
          title: '#10 Add Modal',
          type: 'boolean',
          description: '[packagesScreen.addModal]',
        },
        addModal: {
          type: 'string',
        },
        'packages:enable': {
          title: '#11 Packages',
          type: 'boolean',
          description: '[packagesScreen.packages]',
        },
        packages: {
          type: 'string',
        },
        'cart:enable': {
          title: '#12 Cart',
          type: 'boolean',
          description: '[packagesScreen.cart]',
        },
        cart: {
          type: 'string',
        },
        'total:enable': {
          title: '#13 Total',
          type: 'boolean',
          description: '[packagesScreen.total]',
        },
        total: {
          type: 'string',
        },
        'submit:enable': {
          title: '#14 Submit',
          type: 'boolean',
          description: '[packagesScreen.submit]',
        },
        submit: {
          type: 'string',
        },
        'subheader:enable': {
          title: '#15 Subheader',
          type: 'boolean',
          description: '[packagesScreen.subheader]',
        },
        subheader: {
          type: 'string',
        },
        'description:enable': {
          title: '#16 Description',
          type: 'boolean',
          description: '[packagesScreen.description]',
        },
        description: {
          type: 'string',
        },
        'category:enable': {
          title: '#17 Category',
          type: 'boolean',
          description: '[packagesScreen.category]',
        },
        category: {
          type: 'string',
        },
        'categories:enable': {
          title: '#18 Categories',
          type: 'boolean',
          description: '[packagesScreen.categories]',
        },
        categories: {
          type: 'string',
        },
        'cancel:enable': {
          title: '#19 Cancel',
          type: 'boolean',
          description: '[packagesScreen.cancel]',
        },
        cancel: {
          type: 'string',
        },
        'apply:enable': {
          title: '#20 Apply',
          type: 'boolean',
          description: '[packagesScreen.apply]',
        },
        apply: {
          type: 'string',
        },
        'filter:enable': {
          title: '#21 Filter',
          type: 'boolean',
          description: '[packagesScreen.filter]',
        },
        filter: {
          type: 'string',
        },
        'cartSubheader:enable': {
          title: '#22 Cart Subheader',
          type: 'boolean',
          description: '[packagesScreen.cartSubheader]',
        },
        cartSubheader: {
          type: 'string',
        },
        'cartDescription:enable': {
          title: '#23 Cart Description',
          type: 'boolean',
          description: '[packagesScreen.cartDescription]',
        },
        cartDescription: {
          type: 'string',
        },
        'successTitle:enable': {
          title: '#24 Success Title',
          type: 'boolean',
          description: '[packagesScreen.successTitle]',
        },
        successTitle: {
          type: 'string',
        },
        'successDescription:enable': {
          title: '#25 Success Description',
          type: 'boolean',
          description: '[packagesScreen.successDescription]',
        },
        successDescription: {
          type: 'string',
        },
        'successButtonTitle:enable': {
          title: '#26 Success Button Title',
          type: 'boolean',
          description: '[packagesScreen.successButtonTitle]',
        },
        successButtonTitle: {
          type: 'string',
        },
        'failTitle:enable': {
          title: '#27 Fail Title',
          type: 'boolean',
          description: '[packagesScreen.failTitle]',
        },
        failTitle: {
          type: 'string',
        },
        'failDescription:enable': {
          title: '#28 Fail Description',
          type: 'boolean',
          description: '[packagesScreen.failDescription]',
        },
        failDescription: {
          type: 'string',
        },
        'failButtonTitle:enable': {
          title: '#29 Fail Button Title',
          type: 'boolean',
          description: '[packagesScreen.failButtonTitle]',
        },
        failButtonTitle: {
          type: 'string',
        },
        'emptyCartTitle:enable': {
          title: '#30 Empty Cart Title',
          type: 'boolean',
          description: '[packagesScreen.emptyCartTitle]',
        },
        emptyCartTitle: {
          type: 'string',
        },
        'emptyCartDescription:enable': {
          title: '#31 Empty Cart Description',
          type: 'boolean',
          description: '[packagesScreen.emptyCartDescription]',
        },
        emptyCartDescription: {
          type: 'string',
        },
      },
    },
    paymentCaptureScreen: {
      type: 'object',
      title: '#33 Payment Capture Screen (Mobile Web)',
      description: 'This is applicable for mobile web only',
      properties: {
        'notSupportPaymentProviderText:enable': {
          title: '#1 Not Support Payment Provider Text',
          type: 'boolean',
          description: '[paymentCaptureScreen.notSupportPaymentProviderText]',
        },
        notSupportPaymentProviderText: {
          type: 'string',
        },
        paymentDisabled: {
          type: 'object',
          title: '#2 Payment Disabled',
          properties: {
            'paymentDisabledText1:enable': {
              title: '#2.1 Payment Disabled Text 1',
              type: 'boolean',
              description: '[paymentCaptureScreen.paymentDisabled.paymentDisabledText1]',
            },
            paymentDisabledText1: {
              type: 'string',
            },
            'paymentDisabledText2:enable': {
              title: '#2.2 Payment Disabled Text 2',
              type: 'boolean',
              description: '[paymentCaptureScreen.paymentDisabled.paymentDisabledText2]',
            },
            paymentDisabledText2: {
              type: 'string',
            },
            'button:enable': {
              title: '#2.3 Button',
              type: 'boolean',
              description: '[paymentCaptureScreen.paymentDisabled.button]',
            },
            button: {
              type: 'string',
            },
          },
        },
        'creditCardCaptureLeft:enable': {
          title: '#3 Credit card capture text (first part)',
          type: 'boolean',
          description: '[paymentCaptureScreen.creditCardCaptureLeft]',
        },
        creditCardCaptureLeft: {
          type: 'string',
        },
        'creditCardCaptureRight:enable': {
          title: '#4 Credit card capture text (second part)',
          type: 'boolean',
          description: '[paymentCaptureScreen.creditCardCaptureRight]',
        },
        creditCardCaptureRight: {
          type: 'string',
        },
        'description:enable': {
          title: '#5 Description',
          type: 'boolean',
          description: '[paymentCaptureScreen.description]',
        },
        description: {
          type: 'string',
        },
      },
    },
    notification: {
      type: 'object',
      title: '#34 Notification pop up V3',
      properties: {
        'done:enable': {
          title: '#1 Done Message(Close button)',
          type: 'boolean',
          description: '[notification.done]',
        },
        done: {
          type: 'string',
        },
      },
    },
    shareKeyScreen: {
      type: 'object',
      title: '#35 Share Key Screens',
      description: 'This is applicable for mobile app only',
      properties: {
        tutorialScreen: {
          type: 'object',
          title: '#1 Tutorial Screen',
          properties: {
            'header:enable': {
              title: '#1.1 Tutorial screen header title',
              type: 'boolean',
              description: '[shareKeyScreen.tutorialScreen.header]',
            },
            header: {
              type: 'string',
            },
            'title:enable': {
              title: '#1.2 Tutorial screen title',
              type: 'boolean',
              description: '[shareKeyScreen.tutorialScreen.title]',
            },
            title: {
              type: 'string',
            },
            'description:enable': {
              title: '#1.3 Tutorial screen description',
              type: 'boolean',
              description: '[shareKeyScreen.tutorialScreen.description]',
            },
            description: {
              type: 'string',
            },
            'nextButton:enable': {
              title: '#1.4 Tutorial screen next button text',
              type: 'boolean',
              description: '[shareKeyScreen.tutorialScreen.nextButton]',
            },
            nextButton: {
              type: 'string',
            },
          },
        },
        guestListScreen: {
          type: 'object',
          title: '#2 Guest list Screen',
          properties: {
            'header:enable': {
              title: '#2.1 Guest list screen header title',
              type: 'boolean',
              description: '[shareKeyScreen.guestListScreen.header]',
            },
            header: {
              type: 'string',
            },
            'title:enable': {
              title: '#2.2 Guest list screen title',
              type: 'boolean',
              description: '[shareKeyScreen.guestListScreen.title]',
            },
            title: {
              type: 'string',
            },
            'shareKeyButton:enable': {
              title: '#2.3 Guest list screen share keys button text',
              type: 'boolean',
              description: '[shareKeyScreen.guestListScreen.nextButton]',
            },
            shareKeyButton: {
              type: 'string',
            },
          },
        },
        shareKeyScreen: {
          type: 'object',
          title: '#3 Share keys form screen',
          properties: {
            'header:enable': {
              title: '#3.1 Share keys form screen header title',
              type: 'boolean',
              description: '[shareKeyScreen.shareKeyScreen.header]',
            },
            header: {
              type: 'string',
            },
            'title:enable': {
              title: '#3.2 Share keys form screen title',
              type: 'boolean',
              description: '[shareKeyScreen.shareKeyScreen.title]',
            },
            title: {
              type: 'string',
            },
            'shareKeyButton:enable': {
              title: '#3.3 Share keys form screen share keys button text',
              type: 'boolean',
              description: '[shareKeyScreen.shareKeyScreen.nextButton]',
            },
            shareKeyButton: {
              type: 'string',
            },
            formPlaceholders: {
              type: 'object',
              title: '#3.4 Share keys form screen placeholders',
              properties: {
                'firstName:enable': {
                  title: '#3.4.1 First name placeholder',
                  type: 'boolean',
                  description: '[shareKeyScreen.shareKeyScreen.formPlaceholders.firstName]',
                },
                firstName: {
                  type: 'string',
                },
                'lastName:enable': {
                  title: '#3.4.2 Last name placeholder',
                  type: 'boolean',
                  description: '[shareKeyScreen.shareKeyScreen.formPlaceholders.lastName]',
                },
                lastName: {
                  type: 'string',
                },
                'email:enable': {
                  title: '#3.4.3 Email placeholder',
                  type: 'boolean',
                  description: '[shareKeyScreen.shareKeyScreen.formPlaceholders.email]',
                },
                email: {
                  type: 'string',
                },
                'phoneNumber:enable': {
                  title: '#3.4.4 Phone number placeholder',
                  type: 'boolean',
                  description: '[shareKeyScreen.shareKeyScreen.formPlaceholders.phoneNumber]',
                },
                phoneNumber: {
                  type: 'string',
                },
              },
            },
            successPopup: {
              type: 'object',
              title: '#3.5 Share keys form screen success popup',
              properties: {
                'title:enable': {
                  title: '#3.5.1 Success popup title',
                  type: 'boolean',
                  description: '[shareKeyScreen.shareKeyScreen.successPopup.title]',
                },
                title: {
                  type: 'string',
                },
                'description:enable': {
                  title: '#3.5.2 Success popup description',
                  type: 'boolean',
                  description: '[shareKeyScreen.shareKeyScreen.successPopup.description]',
                },
                description: {
                  type: 'string',
                },
                'okButton:enable': {
                  title: '#3.5.3 Success popup ok button',
                  type: 'boolean',
                  description: '[shareKeyScreen.shareKeyScreen.successPopup.okButton]',
                },
                okButton: {
                  type: 'string',
                },
              },
            },
            errorPopup: {
              type: 'object',
              title: '#3.6 Share keys form screen error popup',
              properties: {
                'title:enable': {
                  title: '#3.6.1 Error popup title',
                  type: 'boolean',
                  description: '[shareKeyScreen.shareKeyScreen.errorPopup.title]',
                },
                title: {
                  type: 'string',
                },
                'description:enable': {
                  title: '#3.6.2 Error popup description',
                  type: 'boolean',
                  description: '[shareKeyScreen.shareKeyScreen.errorPopup.description]',
                },
                description: {
                  type: 'string',
                },
                'okButton:enable': {
                  title: '#3.6.3 Error popup ok button',
                  type: 'boolean',
                  description: '[shareKeyScreen.shareKeyScreen.errorPopup.okButton]',
                },
                okButton: {
                  type: 'string',
                },
              },
            },
          },
        },
        'shareKeyButton:enable': {
          title: '#3 The keycard share key button text',
          type: 'boolean',
          description: '[shareKeyScreen.shareKeyButton]',
        },
        shareKeyButton: {
          type: 'string',
        },
      },
    },
    nameAndDepartureDateVerificationScreen: {
      type: 'object',
      title: '#36 Name and departure date verification screen',
      properties: {
        'emptyData:enable': {
          title: '#1 Empty data',
          type: 'boolean',
          description: '[nameAndDepartureDateVerificationScreen.emptyData]',
        },
        emptyData: {
          type: 'string',
        },
        'title:enable': {
          title: '#2 Title',
          type: 'boolean',
          description: '[nameAndDepartureDateVerificationScreen.title]',
        },
        title: {
          type: 'string',
        },
        'description:enable': {
          title: '#3 Description',
          type: 'boolean',
          description: '[nameAndDepartureDateVerificationScreen.description]',
        },
        description: {
          type: 'string',
        },
        'location:enable': {
          title: '#4 Location',
          type: 'boolean',
          description: '[nameAndDepartureDateVerificationScreen.location]',
        },
        location: {
          type: 'string',
        },
        'firstName:enable': {
          title: '#5 First name',
          type: 'boolean',
          description: '[nameAndDepartureDateVerificationScreen.firstName]',
        },
        firstName: {
          type: 'string',
        },
        'lastName:enable': {
          title: '#6 Last name',
          type: 'boolean',
          description: '[nameAndDepartureDateVerificationScreen.lastName]',
        },
        lastName: {
          type: 'string',
        },
        'departureDate:enable': {
          title: '#7 Departure date',
          type: 'boolean',
          description: '[nameAndDepartureDateVerificationScreen.departureDate]',
        },
        departureDate: {
          type: 'string',
        },
        'errorMessage:enable': {
          title: '#8 Error message',
          type: 'boolean',
          description: '[nameAndDepartureDateVerificationScreen.errorMessage]',
        },
        errorMessage: {
          type: 'string',
        },
        'continue:enable': {
          title: '#9 Continue',
          type: 'boolean',
          description: '[nameAndDepartureDateVerificationScreen.continue]',
        },
        continue: {
          type: 'string',
        },
      },
    },
    userInformationScreen: {
      type: 'object',
      title: '#37 User information screen',
      properties: {
        'title:enable': {
          title: '#1 Title',
          type: 'boolean',
          description: '[userInformationScreen.title]',
        },
        title: {
          type: 'string',
        },
        'subtitle:enable': {
          title: '#2 Subtitle',
          type: 'boolean',
          description: '[userInformationScreen.subtitle]',
        },
        subtitle: {
          type: 'string',
        },
        'detailScreenHeader:enable': {
          title: '#3 Detail screen header',
          type: 'boolean',
          description: '[userInformationScreen.detailScreenHeader]',
        },
        detailScreenHeader: {
          type: 'string',
        },
        'editScreenHeader:enable': {
          title: '#4 Edit screen header',
          type: 'boolean',
          description: '[userInformationScreen.editScreenHeader]',
        },
        editScreenHeader: {
          type: 'string',
        },
        'guests:enable': {
          title: '#5 Guests',
          type: 'boolean',
          description: '[userInformationScreen.guests]',
        },
        guests: {
          type: 'string',
        },
        'nameOnReservation:enable': {
          title: '#6 Name on reservation',
          type: 'boolean',
          description: '[userInformationScreen.nameOnReservation]',
        },
        nameOnReservation: {
          type: 'string',
        },
        'otherGuests:enable': {
          title: '#7 Other guests',
          type: 'boolean',
          description: '[userInformationScreen.otherGuests]',
        },
        otherGuests: {
          type: 'string',
        },
        'continue:enable': {
          title: '#8 Continue',
          type: 'boolean',
          description: '[userInformationScreen.continue]',
        },
        continue: {
          type: 'string',
        },
        'guestDetails:enable': {
          title: '#9 Guest details',
          type: 'boolean',
          description: '[userInformationScreen.guestDetails]',
        },
        guestDetails: {
          type: 'string',
        },
        'guestDetailsDescription:enable': {
          title: '#10 Guests details description',
          type: 'boolean',
          description: '[userInformationScreen.guestDetailsDescription]',
        },
        guestDetailsDescription: {
          type: 'string',
        },
        'update:enable': {
          title: '#11 Update',
          type: 'boolean',
          description: '[userInformationScreen.update]',
        },
        update: {
          type: 'string',
        },
        'editButton:enable': {
          title: '#12 Edit button',
          type: 'boolean',
          description: '[userInformationScreen.editButton]',
        },
        editButton: {
          type: 'string',
        },
      },
    },
    obfuscatedAuthScreen: {
      type: 'object',
      title: '#38 Obfuscated auth screen',
      properties: {
        'title:enable': {
          title: '#1 Title',
          type: 'boolean',
          description: '[obfuscatedAuthScreen.title]',
        },
        title: {
          type: 'string',
        },
        'subtitle:enable': {
          title: '#2 Subtitle',
          type: 'boolean',
          description: '[obfuscatedAuthScreen.subtitle]',
        },
        subtitle: {
          type: 'string',
        },
        'emailLabel:enable': {
          title: '#3 Email label',
          type: 'boolean',
          description: '[obfuscatedAuthScreen.emailLabel]',
        },
        emailLabel: {
          type: 'string',
        },
        'phoneNumberLabel:enable': {
          title: '#4 Phone number label',
          type: 'boolean',
          description: '[obfuscatedAuthScreen.phoneNumberLabel]',
        },
        phoneNumberLabel: {
          type: 'string',
        },
      },
    },
    confirmationCodeVerificationScreen: {
      type: 'object',
      title: '#39 Confirmation code verification screen',
      properties: {
        'emptyData:enable': {
          title: '#1 Empty data',
          type: 'boolean',
          description: '[confirmationCodeVerificationScreen.emptyData]',
        },
        emptyData: {
          type: 'string',
        },
        'title:enable': {
          title: '#2 Title',
          type: 'boolean',
          description: '[confirmationCodeVerificationScreen.title]',
        },
        title: {
          type: 'string',
        },
        'description:enable': {
          title: '#3 Description',
          type: 'boolean',
          description: '[confirmationCodeVerificationScreen.description]',
        },
        description: {
          type: 'string',
        },
        'location:enable': {
          title: '#4 Location',
          type: 'boolean',
          description: '[confirmationCodeVerificationScreen.location]',
        },
        location: {
          type: 'string',
        },
        'confirmationCode:enable': {
          title: '#5 Confirmation code',
          type: 'boolean',
          description: '[confirmationCodeVerificationScreen.confirmationCode]',
        },
        confirmationCode: {
          type: 'string',
        },
        'errorMessage:enable': {
          title: '#6 Error message',
          type: 'boolean',
          description: '[confirmationCodeVerificationScreen.errorMessage]',
        },
        errorMessage: {
          type: 'string',
        },
        'continue:enable': {
          title: '#7 Continue',
          type: 'boolean',
          description: '[confirmationCodeVerificationScreen.continue]',
        },
        continue: {
          type: 'string',
        },
      },
    },
    expectedArrivalScreen: {
      type: 'object',
      title: '#40 Expected arrival screen',
      properties: {
        'title:enable': {
          title: '#1 Screen title',
          type: 'boolean',
          description: '[expectedArrivalScreen.title]',
        },
        title: {
          type: 'string',
        },
        'description:enable': {
          title: '#2 Screen description',
          type: 'boolean',
          description: '[expectedArrivalScreen.description]',
        },
        description: {
          type: 'string',
        },
        'submitButton:enable': {
          title: '#3 Submit button text',
          type: 'boolean',
          description: '[expectedArrivalScreen.submitButton]',
        },
        submitButton: {
          type: 'string',
        },
        'alertSuccessTitle:enable': {
          title: '#4 Success popup title',
          type: 'boolean',
          description: '[expectedArrivalScreen.alertSuccessTitle]',
        },
        alertSuccessTitle: {
          type: 'string',
        },
        'alertSuccessDescription:enable': {
          title: '#5 Success popup decription',
          type: 'boolean',
          description: '[expectedArrivalScreen.alertSuccessDescription]',
        },
        alertSuccessDescription: {
          type: 'string',
        },
        'alertSuccessOkButton:enable': {
          title: '#6 Success popup ok button text',
          type: 'boolean',
          description: '[expectedArrivalScreen.alertSuccessOkButton]',
        },
        alertSuccessOkButton: {
          type: 'string',
        },
        'alertErrorTitle:enable': {
          title: '#7 Error popup title',
          type: 'boolean',
          description: '[expectedArrivalScreen.alertErrorTitle]',
        },
        alertErrorTitle: {
          type: 'string',
        },
        'alertErrorDescription:enable': {
          title: '#8 Error popup description',
          type: 'boolean',
          description: '[expectedArrivalScreen.alertErrorDescription]',
        },
        alertErrorDescription: {
          type: 'string',
        },
        'alertErrorOkButton:enable': {
          title: '#9 Error popup ok button text',
          type: 'boolean',
          description: '[expectedArrivalScreen.alertErrorOkButton]',
        },
        alertErrorOkButton: {
          type: 'string',
        },
        'alertConfirmationTitle:enable': {
          title: '#10 Confirmartion popup title',
          type: 'boolean',
          description: '[expectedArrivalScreen.alertConfirmationTitle]',
        },
        alertConfirmationTitle: {
          type: 'string',
        },
        'alertConfirmationDescription:enable': {
          title: '#11 Confirmartion popup description',
          type: 'boolean',
          description: '[expectedArrivalScreen.alertConfirmationDescription]',
        },
        alertConfirmationDescription: {
          type: 'string',
        },
        'alertConfirmationCancelButton:enable': {
          title: '#12 Confirmartion popup cancel button text',
          type: 'boolean',
          description: '[expectedArrivalScreen.alertConfirmationCancelButton]',
        },
        alertConfirmationCancelButton: {
          type: 'string',
        },
        'alertConfirmationApplyButton:enable': {
          title: '#13 Confirmartion popup ok button text',
          type: 'boolean',
          description: '[expectedArrivalScreen.alertConfirmationApplyButton]',
        },
        alertConfirmationApplyButton: {
          type: 'string',
        },
        'switchLabel:enable': {
          title: '#14 24 hours format toogle label',
          type: 'boolean',
          description: '[expectedArrivalScreen.switchLabel]',
        },
        switchLabel: {
          type: 'string',
        },
      },
    },
    checkoutScreen: {
      type: 'object',
      title: '#41 Check-out screen',
      properties: {
        'title:enable': {
          title: '#1 Title',
          type: 'boolean',
          description: '[checkoutScreen.title]',
        },
        title: {
          type: 'string',
        },
        'cardOnFileTitle:enable': {
          title: '#2 Card on file title',
          type: 'boolean',
          description: '[checkoutScreen.cardOnFileTitle]',
        },
        cardOnFileTitle: {
          type: 'string',
        },
        'itemHeader:enable': {
          title: '#3 Header check-out item text',
          type: 'boolean',
          description: '[checkoutScreen.itemHeader]',
        },
        itemHeader: {
          type: 'string',
        },
        'priceHeader:enable': {
          title: '#4 Header price text',
          type: 'boolean',
          description: '[checkoutScreen.priceHeader]',
        },
        priceHeader: {
          type: 'string',
        },
        'totalPriceHeader:enable': {
          title: '#5 Header total price text',
          type: 'boolean',
          description: '[checkoutScreen.totalPriceHeader]',
        },
        totalPriceHeader: {
          type: 'string',
        },
        'totalFooter:enable': {
          title: '#6 Footer total text',
          type: 'boolean',
          description: '[checkoutScreen.totalFooter]',
        },
        totalFooter: {
          type: 'string',
        },
        'checkoutButton:enable': {
          title: '#7 Check-out button text',
          type: 'boolean',
          description: '[checkoutScreen.checkoutButton]',
        },
        checkoutButton: {
          type: 'string',
        },
        'checkoutFailed:enable': {
          title: '#8 Check-out failure fallback text',
          type: 'boolean',
          description: '[checkoutScreen.checkoutFailed]',
        },
        checkoutFailed: {
          type: 'string',
        },
        'checkOutFailureAlertTitle:enable': {
          title: '#9 Check-out failure alert title',
          type: 'boolean',
          description: '[checkoutScreen.checkOutFailureAlertTitle]',
        },
        checkOutFailureAlertTitle: {
          type: 'string',
        },
        'checkOutSuccessfulAlertTitle:enable': {
          title: '#10 Check-out successful alert title',
          type: 'boolean',
          description: '[checkoutScreen.checkOutSuccessfulAlertTitle]',
        },
        checkOutSuccessfulAlertTitle: {
          type: 'string',
        },
        'checkOutSuccessfulAlertText:enable': {
          title: '#11 Check-out successful alert text',
          type: 'boolean',
          description: '[checkoutScreen.checkOutSuccessfulAlertText]',
        },
        checkOutSuccessfulAlertText: {
          type: 'string',
        },
      },
    },
    remoteAssistanceModal: {
      type: 'object',
      title: '#42 Remote assistance modal',
      properties: {
        'directMessage:enable': {
          title: '#1 Direct message button text',
          type: 'boolean',
          description: '[remoteAssistanceModal.directMessage]',
        },
        directMessage: {
          type: 'string',
        },
        'voice:enable': {
          title: '#2 Voice button text',
          type: 'boolean',
          description: '[remoteAssistanceModal.voice]',
        },
        voice: {
          type: 'string',
        },
        'video:enable': {
          title: '#3 Video button text',
          type: 'boolean',
          description: '[remoteAssistanceModal.video]',
        },
        video: {
          type: 'string',
        },
        'end:enable': {
          title: '#4 End button text',
          type: 'boolean',
          description: '[remoteAssistanceModal.end]',
        },
        end: {
          type: 'string',
        },
        'mute:enable': {
          title: '#5 Mute button text',
          type: 'boolean',
          description: '[remoteAssistanceModal.mute]',
        },
        mute: {
          type: 'string',
        },
      },
    },
    scanBarcodeScreen: {
      type: 'object',
      title: '#43 Scan Barcode Screen (Mobile Web)',
      description: 'This is applicable for mobile web only',
      properties: {
        'title:enable': {
          title: '#1 Title (top navigation bar)',
          type: 'boolean',
          description: '[scanBarcodeScreen.title]',
        },
        title: {
          type: 'string',
        },
        'header:enable': {
          title: '#2 Header',
          type: 'boolean',
          description: '[scanBarcodeScreen.header]',
        },
        header: {
          type: 'string',
        },
        'subheader:enable': {
          title: '#3 Subheader',
          type: 'boolean',
          description: '[scanBarcodeScreen.subheader]',
        },
        subheader: {
          type: 'string',
        },
        success: {
          type: 'object',
          title: '#4 Success modal',
          properties: {
            'title:enable': {
              title: '#4.1 Title',
              type: 'boolean',
              description: '[scanBarcodeScreen.success.title]',
            },
            title: {
              type: 'string',
            },
            'description:enable': {
              title: '#4.2 Description',
              type: 'boolean',
              description: '[scanBarcodeScreen.success.description]',
            },
            description: {
              type: 'string',
            },
            'button:enable': {
              title: '#4.3 Button',
              type: 'boolean',
              description: '[scanBarcodeScreen.success.button]',
            },
            button: {
              type: 'string',
            },
          },
        },
        'close:enable': {
          title: '#5 Close',
          type: 'boolean',
          description: '[scanBarcodeScreen.close]',
        },
        close: {
          type: 'string',
        },
        fail: {
          type: 'object',
          title: '#6 Fail modal',
          properties: {
            'title:enable': {
              title: '#6.1 Title',
              type: 'boolean',
              description: '[scanBarcodeScreen.fail.title]',
            },
            title: {
              type: 'string',
            },
            'description:enable': {
              title: '#6.2 Description',
              type: 'boolean',
              description: '[scanBarcodeScreen.fail.description]',
            },
            description: {
              type: 'string',
            },
            'button:enable': {
              title: '#6.3 Button',
              type: 'boolean',
              description: '[scanBarcodeScreen.fail.button]',
            },
            button: {
              type: 'string',
            },
          },
        },
        instruction: {
          type: 'object',
          title: '#7 Instruction bottom sheet',
          properties: {
            'header:enable': {
              title: '#7.1 Header',
              type: 'boolean',
              description: '[scanBarcodeScreen.instruction.header]',
            },
            header: {
              type: 'string',
            },
            'subheader:enable': {
              title: '#7.2 Subheader',
              type: 'boolean',
              description: '[scanBarcodeScreen.instruction.subheader]',
            },
            subheader: {
              type: 'string',
            },
            'info:enable': {
              title: '#7.3 Information text',
              type: 'boolean',
              description: '[scanBarcodeScreen.instruction.info]',
            },
            info: {
              type: 'string',
            },
            'imageTitle:enable': {
              title: '#7.4 Instruction image title',
              type: 'boolean',
              description: '[scanBarcodeScreen.instruction.imageTitle]',
            },
            imageTitle: {
              type: 'string',
            },
            'buttonText:enable': {
              title: '#7.5 Bottom sticky button text',
              type: 'boolean',
              description: '[scanBarcodeScreen.instruction.buttonText]',
            },
            buttonText: {
              type: 'string',
            },
          },
        },
      },
    },
    scheduleCheckInScreen: {
      type: 'object',
      title: '#44 Schedule check-in screen',
      description: 'This is applicable for mobile web only',
      properties: {
        'title:enable': {
          title: '#1 Screen title',
          type: 'boolean',
          description: '[scheduleCheckInScreen.title]',
        },
        title: {
          type: 'string',
        },
        'description:enable': {
          title: '#2 Screen description',
          type: 'boolean',
          description: '[scheduleCheckInScreen.description]',
        },
        description: {
          type: 'string',
        },
        'submitButton:enable': {
          title: '#3 Submit button text',
          type: 'boolean',
          description: '[scheduleCheckInScreen.submitButton]',
        },
        submitButton: {
          type: 'string',
        },
        'alertSuccessTitle:enable': {
          title: '#4 Success popup title',
          type: 'boolean',
          description: '[scheduleCheckInScreen.alertSuccessTitle]',
        },
        alertSuccessTitle: {
          type: 'string',
        },
        'alertSuccessDescription:enable': {
          title: '#5 Success popup decription',
          type: 'boolean',
          description: '[scheduleCheckInScreen.alertSuccessDescription]',
        },
        alertSuccessDescription: {
          type: 'string',
        },
        'alertSuccessOkButton:enable': {
          title: '#6 Success popup ok button text',
          type: 'boolean',
          description: '[scheduleCheckInScreen.alertSuccessOkButton]',
        },
        alertSuccessOkButton: {
          type: 'string',
        },
        'alertErrorTitle:enable': {
          title: '#7 Error popup title',
          type: 'boolean',
          description: '[scheduleCheckInScreen.alertErrorTitle]',
        },
        alertErrorTitle: {
          type: 'string',
        },
        'alertErrorDescription:enable': {
          title: '#8 Error popup description',
          type: 'boolean',
          description: '[scheduleCheckInScreen.alertErrorDescription]',
        },
        alertErrorDescription: {
          type: 'string',
        },
        'alertErrorOkButton:enable': {
          title: '#9 Error popup ok button text',
          type: 'boolean',
          description: '[scheduleCheckInScreen.alertErrorOkButton]',
        },
        alertErrorOkButton: {
          type: 'string',
        },
        'alertConfirmationTitle:enable': {
          title: '#10 Confirmartion popup title',
          type: 'boolean',
          description: '[scheduleCheckInScreen.alertConfirmationTitle]',
        },
        alertConfirmationTitle: {
          type: 'string',
        },
        'alertConfirmationDescription:enable': {
          title: '#11 Confirmartion popup description',
          type: 'boolean',
          description: '[scheduleCheckInScreen.alertConfirmationDescription]',
        },
        alertConfirmationDescription: {
          type: 'string',
        },
        'alertConfirmationCancelButton:enable': {
          title: '#12 Confirmartion popup cancel button text',
          type: 'boolean',
          description: '[scheduleCheckInScreen.alertConfirmationCancelButton]',
        },
        alertConfirmationCancelButton: {
          type: 'string',
        },
        'alertConfirmationApplyButton:enable': {
          title: '#13 Confirmartion popup ok button text',
          type: 'boolean',
          description: '[scheduleCheckInScreen.alertConfirmationApplyButton]',
        },
        alertConfirmationApplyButton: {
          type: 'string',
        },
        'switchLabel:enable': {
          title: '#14 24 hours format toogle label',
          type: 'boolean',
          description: '[scheduleCheckInScreen.switchLabel]',
        },
        switchLabel: {
          type: 'string',
        },
        'dateNotSelectedAlertTitle:enable': {
          title: '#15 Date has not been select alert title',
          type: 'boolean',
          description: '[scheduleCheckInScreen.dateNotSelectedAlertTitle]',
        },
        dateNotSelectedAlertTitle: {
          type: 'string',
        },
        'featureNotEnabledTitle:enable': {
          title: '#16 Schedule check-in feature not enabled alert title',
          type: 'boolean',
          description: '[scheduleCheckInScreen.featureNotEnabledTitle]',
        },
        featureNotEnabledTitle: {
          type: 'string',
        },
        'scheduleDateHasBeenSetText:enable': {
          title: '#17 Schedule check-in date has been set alert text',
          type: 'boolean',
          description: '[scheduleCheckInScreen.scheduleDateHasBeenSetText]',
        },
        scheduleDateHasBeenSetText: {
          type: 'string',
        },
        'selectDate:enable': {
          title: '#18 Select date input placeholder text',
          type: 'boolean',
          description: '[scheduleCheckInScreen.selectDate]',
        },
        selectDate: {
          type: 'string',
        },
        'timeCaption:enable': {
          title: '#19 Time picker modal header',
          type: 'boolean',
          description: '[scheduleCheckInScreen.timeCaption]',
        },
        timeCaption: {
          type: 'string',
        },
      },
    },
    faceLivenessScreen: {
      type: 'object',
      title: '#45 Face liveness screen',
      properties: {
        'backLabel:enable': {
          title: '#1 Back label',
          type: 'boolean',
          description: '[faceLivenessScreen.backLabel]',
        },
        backLabel: {
          type: 'string',
        },
        'cameraText:enable': {
          title: '#2 Camera text',
          type: 'boolean',
          description: '[faceLivenessScreen.cameraText]',
        },
        cameraText: {
          type: 'string',
        },
        'instructionText:enable': {
          title: '#3 Instruction text',
          type: 'boolean',
          description: '[faceLivenessScreen.instructionText]',
        },
        instructionText: {
          type: 'string',
        },
        'instructionDescriptionText:enable': {
          title: '#4 Instruction description text',
          type: 'boolean',
          description: '[faceLivenessScreen.instructionDescriptionText]',
        },
        instructionDescriptionText: {
          type: 'string',
        },
        'backAlert:enable': {
          title: '#5 Back alert text',
          type: 'boolean',
          description: '[faceLivenessScreen.backAlert]',
        },
        backAlert: {
          type: 'string',
        },
        'remoteAssistanceAlert:enable': {
          title: '#6 Remote assistance alert text',
          type: 'boolean',
          description: '[faceLivenessScreen.remoteAssistanceAlert]',
        },
        remoteAssistanceAlert: {
          type: 'string',
        },
        'homeAlert:enable': {
          title: '#7 Home alert text',
          type: 'boolean',
          description: '[faceLivenessScreen.homeAlert]',
        },
        homeAlert: {
          type: 'string',
        },
        'backAlertPositiveButton:enable': {
          title: '#8 Back alert positive button text',
          type: 'boolean',
          description: '[faceLivenessScreen.backAlertPositiveButton]',
        },
        backAlertPositiveButton: {
          type: 'string',
        },
        'remoteAssistanceAlertPositiveButton:enable': {
          title: '#9 Remote assistance alert positive button text',
          type: 'boolean',
          description: '[faceLivenessScreen.remoteAssistanceAlertPositiveButton]',
        },
        remoteAssistanceAlertPositiveButton: {
          type: 'string',
        },
        'homeAlertPositiveButton:enable': {
          title: '#10 Home alert positive button text',
          type: 'boolean',
          description: '[faceLivenessScreen.homeAlertPositiveButton]',
        },
        homeAlertPositiveButton: {
          type: 'string',
        },
        'backAlertNegativeButton:enable': {
          title: '#11 Back alert negative button text',
          type: 'boolean',
          description: '[faceLivenessScreen.backAlertNegativeButton]',
        },
        backAlertNegativeButton: {
          type: 'string',
        },
        'remoteAssistanceAlertNegativeButton:enable': {
          title: '#12 Remote assistance alert negative button text',
          type: 'boolean',
          description: '[faceLivenessScreen.remoteAssistanceAlertNegativeButton]',
        },
        remoteAssistanceAlertNegativeButton: {
          type: 'string',
        },
        'homeAlertNegativeButton:enable': {
          title: '#13 Home alert negative button text',
          type: 'boolean',
          description: '[faceLivenessScreen.homeAlertNegativeButton]',
        },
        homeAlertNegativeButton: {
          type: 'string',
        },
        'headerText:enable': {
          title: '#14 Header text',
          type: 'boolean',
          description: '[faceLivenessScreen.headerText]',
        },
        headerText: {
          type: 'string',
        },
        'subheaderText:enable': {
          title: '#15 Subheader text',
          type: 'boolean',
          description: '[faceLivenessScreen.subheaderText]',
        },
        subheaderText: {
          type: 'string',
        },
        'startButtonText:enable': {
          title: '#16 Start button text',
          type: 'boolean',
          description: '[faceLivenessScreen.startButtonText]',
        },
        startButtonText: {
          type: 'string',
        },
        'faceMatchFailedDescription:enable': {
          title: '#17 Face match failed description text',
          type: 'boolean',
          description: '[faceLivenessScreen.faceMatchFailedDescription]',
        },
        faceMatchFailedDescription: {
          type: 'string',
        },
        'retryButtonText:enable': {
          title: '#18 Retry button text',
          type: 'boolean',
          description: '[faceLivenessScreen.retryButtonText]',
        },
        retryButtonText: {
          type: 'string',
        },
        'endSessionButtonText:enable': {
          title: '#19 End session button text',
          type: 'boolean',
          description: '[faceLivenessScreen.endSessionButtonText]',
        },
        endSessionButtonText: {
          type: 'string',
        },
        'faceLivenessFailedDescription:enable': {
          title: '#20 Face liveness failed description text',
          type: 'boolean',
          description: '[faceLivenessScreen.faceLivenessFailedDescription]',
        },
        faceLivenessFailedDescription: {
          type: 'string',
        },
      },
    },
  },
};

export const mobileLocalizationUiSchema = {
  general: {
    'ui:title': '‎',
    error: {
      'ui:label': false,
      'ui:readonly': true,
    },
    or: {
      'ui:label': false,
      'ui:readonly': true,
    },
    continue: {
      'ui:label': false,
      'ui:readonly': true,
    },
    submit: {
      'ui:label': false,
      'ui:readonly': true,
    },
    signinAnotherWay: {
      'ui:label': false,
      'ui:readonly': true,
    },
    cancel: {
      'ui:label': false,
      'ui:readonly': true,
    },
    done: {
      'ui:label': false,
      'ui:readonly': true,
    },
    remove: {
      'ui:label': false,
      'ui:readonly': true,
    },
    start: {
      'ui:label': false,
      'ui:readonly': true,
    },
    firstNamePlaceholder: {
      'ui:label': false,
      'ui:readonly': true,
    },
    lastNamePlaceholder: {
      'ui:label': false,
      'ui:readonly': true,
    },
    emailPlaceholder: {
      'ui:label': false,
      'ui:readonly': true,
    },
    phoneNumberPlaceholder: {
      'ui:label': false,
      'ui:readonly': true,
    },
    addPaymentFailedTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    addPaymentFailedButtonText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    floorPrefix: {
      'ui:label': false,
      'ui:readonly': true,
    },
    floor: {
      'ui:label': false,
      'ui:readonly': true,
    },
    floorSuffix: {
      'ui:label': false,
      'ui:readonly': true,
    },
    buildingPrefix: {
      'ui:label': false,
      'ui:readonly': true,
    },
    building: {
      'ui:label': false,
      'ui:readonly': true,
    },
    buildingSuffix: {
      'ui:label': false,
      'ui:readonly': true,
    },
    forceUpdateTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    forceUpdateButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    checkIn: {
      'ui:label': false,
      'ui:readonly': true,
    },
    downloadMobileApp: {
      'ui:label': false,
      'ui:readonly': true,
    },
    verifyId: {
      'ui:label': false,
      'ui:readonly': true,
    },
    paymentAuthorization: {
      'ui:label': false,
      'ui:readonly': true,
    },
    paymentStatus: {
      'ui:label': false,
      'ui:readonly': true,
    },
    paymentCapture: {
      'ui:label': false,
      'ui:readonly': true,
    },
    termsAndConditions: {
      'ui:label': false,
      'ui:readonly': true,
    },
    next: {
      'ui:label': false,
      'ui:readonly': true,
    },
    retry: {
      'ui:label': false,
      'ui:readonly': true,
    },
    clear: {
      'ui:label': false,
      'ui:readonly': true,
    },
    disabledOnDesktop: {
      'ui:label': false,
      'ui:readonly': true,
    },
    mrzIdVerification: {
      'ui:label': false,
      'ui:readonly': true,
    },
    walletPass: {
      'ui:label': false,
      'ui:readonly': true,
    },
    cameraPermissionDeniediOS: {
      'ui:label': false,
      'ui:readonly': true,
    },
    cameraPermissionDeniedAndroid: {
      'ui:label': false,
      'ui:readonly': true,
    },
    humanReadableErrors: {
      'ui:label': false,
      'ui:title': '‎',
      'ui:readonly': true,
      items: {
        code: {},
        message: {},
      },
    },
  },
  updatePasswordScreen: {
    'ui:title': '‎',
    setPasswordSuccessfully: {
      'ui:label': false,
      'ui:readonly': true,
    },
    passwordMismatch: {
      'ui:label': false,
      'ui:readonly': true,
    },
    setPassword: {
      'ui:label': false,
      'ui:readonly': true,
    },
    password: {
      'ui:label': false,
      'ui:readonly': true,
    },
    confirmationPassword: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  captureVendorIDScreen: {
    'ui:title': '‎',
    vendorCard: {
      'ui:label': false,
      'ui:readonly': true,
    },
    snap: {
      'ui:label': false,
      'ui:readonly': true,
    },
    screenTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  personalDetailsScreen: {
    'ui:title': '‎',
    screenTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    loading: {
      'ui:label': false,
      'ui:readonly': true,
    },
    error: {
      'ui:label': false,
      'ui:readonly': true,
    },
    personalInformation: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  dashboardScreen: {
    'ui:title': '‎',
    completeThisTask: {
      'ui:label': false,
      'ui:readonly': true,
    },
    noAccessGrant: {
      'ui:label': false,
      'ui:readonly': true,
    },
    idVerification: {
      'ui:label': false,
      'ui:readonly': true,
    },
    addPayment: {
      'ui:label': false,
      'ui:readonly': true,
    },
    addAuthorization: {
      'ui:label': false,
      'ui:readonly': true,
    },
    securityDeposit: {
      'ui:label': false,
      'ui:readonly': true,
    },
    yourStayTab: {
      'ui:label': false,
      'ui:readonly': true,
    },
    servicesTab: {
      'ui:label': false,
      'ui:readonly': true,
    },
    stayTab: {
      'ui:label': false,
      'ui:readonly': true,
    },
    yourTourTab: {
      'ui:label': false,
      'ui:readonly': true,
    },
    buildingTab: {
      'ui:label': false,
      'ui:readonly': true,
    },
    tourTab: {
      'ui:label': false,
      'ui:readonly': true,
    },
    amenitiesMenu: {
      'ui:label': false,
      'ui:readonly': true,
    },
    keyCardMenu: {
      'ui:label': false,
      'ui:readonly': true,
    },
    qrMenu: {
      'ui:label': false,
      'ui:readonly': true,
    },
    infoMenu: {
      'ui:label': false,
      'ui:readonly': true,
    },
    reservation: {
      'ui:label': false,
      'ui:readonly': true,
    },
    nextTour: {
      'ui:label': false,
      'ui:readonly': true,
    },
    checkinLabelLeasing: {
      'ui:label': false,
      'ui:readonly': true,
    },
    checkinLabelHotel: {
      'ui:label': false,
      'ui:readonly': true,
    },
    checkoutLabelHotel: {
      'ui:label': false,
      'ui:readonly': true,
    },
    clickForMore: {
      'ui:label': false,
      'ui:readonly': true,
    },
    roomNumberWillShown: {
      'ui:label': false,
      'ui:readonly': true,
    },
    showAll: {
      'ui:label': false,
      'ui:readonly': true,
    },
    failedToSendServiceRequest: {
      'ui:label': false,
      'ui:readonly': true,
    },
    youHaveSelected: {
      'ui:label': false,
      'ui:readonly': true,
    },
    comment: {
      'ui:label': false,
      'ui:readonly': true,
    },
    requestSent: {
      'ui:label': false,
      'ui:readonly': true,
    },
    requestTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    sendRequest: {
      'ui:label': false,
      'ui:readonly': true,
    },
    time: {
      'ui:label': false,
      'ui:readonly': true,
    },
    availableDuringCheckin: {
      'ui:label': false,
      'ui:readonly': true,
    },
    pleaseCompleteAllRequirement: {
      'ui:label': false,
      'ui:readonly': true,
    },
    serviceAvailableAfterCheckin: {
      'ui:label': false,
      'ui:readonly': true,
    },
    completeAllRequirement: {
      'ui:label': false,
      'ui:readonly': true,
    },
    issueKeycard: {
      'ui:label': false,
      'ui:readonly': true,
    },
    tapToUse: {
      'ui:label': false,
      'ui:readonly': true,
    },
    lockServiceCode: {
      'ui:label': false,
      'ui:readonly': true,
    },
    blueTooth: {
      'ui:label': false,
      'ui:readonly': true,
    },
    stableNetwork: {
      'ui:label': false,
      'ui:readonly': true,
    },
    failedToCreateMobileKey: {
      'ui:label': false,
      'ui:readonly': true,
    },
    holdNearScanner: {
      'ui:label': false,
      'ui:readonly': true,
    },
    food: {
      'ui:label': false,
      'ui:readonly': true,
    },
    room: {
      'ui:label': false,
      'ui:readonly': true,
    },
    yourBooking: {
      'ui:label': false,
      'ui:readonly': true,
    },
    confirmationAGSheet: {
      'ui:label': false,
      'ui:readonly': true,
    },
    checkinAGSheet: {
      'ui:label': false,
      'ui:readonly': true,
    },
    scanToUse: {
      'ui:label': false,
      'ui:readonly': true,
    },
    remoteAssistanceRequirementOverlayTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    remoteAssistanceRequirementOverlayButtonTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    requirementsFailureAlertTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    checkInFailureAlertTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    checkInFailureAlertDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    keycardRoomNumberSuffixText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    keycardHeaderText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    limitKeysReached: {
      'ui:label': false,
      'ui:readonly': true,
    },
    scanLockListAgain: {
      'ui:label': false,
      'ui:readonly': true,
    },
    requirementOverlayTitleStart: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  paymentInfoScreen: {
    'ui:title': '‎',
    removeCreditCard: {
      'ui:label': false,
      'ui:readonly': true,
    },
    creditCard: {
      'ui:label': false,
      'ui:readonly': true,
    },
    addCard: {
      'ui:label': false,
      'ui:readonly': true,
    },
    screenTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    confirmRemoveCard: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  IDVerificationScreen: {
    'ui:title': '‎',
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    verifyIDTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    verifyDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    safetyTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    safetyDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    securityTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    securityDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    startButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  waitingIDVerificationScreen: {
    'ui:title': '‎',
    checkResult: {
      'ui:label': false,
      'ui:readonly': true,
    },
    oneMinute: {
      'ui:label': false,
      'ui:readonly': true,
    },
    idVerificationFailed: {
      'ui:label': false,
      'ui:readonly': true,
    },
    confirmReservation: {
      'ui:label': false,
      'ui:readonly': true,
    },
    startButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  resetPasswordScreen: {
    'ui:title': '‎',
    passwordResetSuccessfully: {
      'ui:label': false,
      'ui:readonly': true,
    },
    signinWithNewPassword: {
      'ui:label': false,
      'ui:readonly': true,
    },
    passwordMismatch: {
      'ui:label': false,
      'ui:readonly': true,
    },
    newPassword: {
      'ui:label': false,
      'ui:readonly': true,
    },
    password: {
      'ui:label': false,
      'ui:readonly': true,
    },
    confirmPassword: {
      'ui:label': false,
      'ui:readonly': true,
    },
    submitButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  validateCodeScreen: {
    'ui:title': '‎',
    continue: {
      'ui:label': false,
      'ui:readonly': true,
    },
    verifyCode: {
      'ui:label': false,
      'ui:readonly': true,
    },
    checkYourInboxLeft: {
      'ui:label': false,
      'ui:readonly': true,
    },
    checkYourInboxRight: {
      'ui:label': false,
      'ui:readonly': true,
    },
    youTheCodeAt: {
      'ui:label': false,
      'ui:readonly': true,
    },
    resendCode: {
      'ui:label': false,
      'ui:readonly': true,
    },
    codeSent: {
      'ui:label': false,
      'ui:readonly': true,
    },
    fallbackResendCodeError: {
      'ui:label': false,
      'ui:readonly': true,
    },
    fallbackIncorrectCodeError: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  loginIDVerificationScreen: {
    'ui:title': '‎',
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    startVerifyButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  forgotPasswordScreen: {
    'ui:title': '‎',
    resetPasswordLink: {
      'ui:label': false,
      'ui:readonly': true,
    },
    pleaseCheck: {
      'ui:label': false,
      'ui:readonly': true,
    },
    followLink: {
      'ui:label': false,
      'ui:readonly': true,
    },
    forgotPassword: {
      'ui:label': false,
      'ui:readonly': true,
    },
    sendYourPassword: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  notification: {
    'ui:title': '‎',
    done: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  loginEmailScreen: {
    'ui:title': '‎',
    validEmail: {
      'ui:label': false,
      'ui:readonly': true,
    },
    submitButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    emailPlaceholder: {
      'ui:label': false,
      'ui:readonly': true,
    },
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    description: {
      'ui:label': false,
      'ui:readonly': true,
    },
    or: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  loginScreen: {
    'ui:title': '‎',
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    signin: {
      'ui:label': false,
      'ui:readonly': true,
    },
    email: {
      'ui:label': false,
      'ui:readonly': true,
    },
    phoneNumber: {
      'ui:label': false,
      'ui:readonly': true,
    },
    confirmationCode: {
      'ui:label': false,
      'ui:readonly': true,
    },
    nameAndDepartureDate: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  profileScreen: {
    'ui:title': '‎',
    screenTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    supportSectionTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    QATitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    feedback: {
      'ui:label': false,
      'ui:readonly': true,
    },
    tos: {
      'ui:label': false,
      'ui:readonly': true,
    },
    language: {
      'ui:label': false,
      'ui:readonly': true,
    },
    logout: {
      'ui:label': false,
      'ui:readonly': true,
    },
    logoutAlertTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    areYouSureAlertText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    cancelText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    accountSetting: {
      'ui:label': false,
      'ui:readonly': true,
    },
    personalInformation: {
      'ui:label': false,
      'ui:readonly': true,
    },
    payment: {
      'ui:label': false,
      'ui:readonly': true,
    },
    verify: {
      'ui:label': false,
      'ui:readonly': true,
    },
    addCorporateId: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  loginPasswordScreen: {
    'ui:title': '‎',
    confirmPassword: {
      'ui:label': false,
      'ui:readonly': true,
    },
    pleaseConfirm: {
      'ui:label': false,
      'ui:readonly': true,
    },
    forgotPassword: {
      'ui:label': false,
      'ui:readonly': true,
    },
    submit: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  paymentAuthorizationScreen: {
    'ui:title': '‎',
    internalServerError: {
      'ui:label': false,
      'ui:readonly': true,
    },
    onlyAuthorized: {
      'ui:label': false,
      'ui:readonly': true,
    },
    creditCardAuthorizeLeft: {
      'ui:label': false,
      'ui:readonly': true,
    },
    creditCardAuthorizeRight: {
      'ui:label': false,
      'ui:readonly': true,
    },
    screenTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    yourCardWillNotBeChargedText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    notSupportPaymentProviderText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    pleaseProvideCreditCardText1: {
      'ui:label': false,
      'ui:readonly': true,
    },
    pleaseProvideCreditCardText2: {
      'ui:label': false,
      'ui:readonly': true,
    },
    paymentDisabled: {
      paymentDisabledText1: {
        'ui:label': false,
        'ui:readonly': true,
      },
      paymentDisabledText2: {
        'ui:label': false,
        'ui:readonly': true,
      },
      button: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
  },
  paymentFormScreen: {
    'ui:title': '‎',
    failedToAddPayment: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  loginPhoneScreen: {
    'ui:title': '‎',
    enterValidPhoneNumber: {
      'ui:label': false,
      'ui:readonly': true,
    },
    codeSend: {
      'ui:label': false,
      'ui:readonly': true,
    },
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    submit: {
      'ui:label': false,
      'ui:readonly': true,
    },
    phonePlaceholder: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  confirmationNumberScreen: {
    'ui:title': '‎',
    confirmationNumber: {
      'ui:label': false,
      'ui:readonly': true,
    },
    emptyData: {
      'ui:label': false,
      'ui:readonly': true,
    },
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    signinContinue: {
      'ui:label': false,
      'ui:readonly': true,
    },
    enterConfirmNumber: {
      'ui:label': false,
      'ui:readonly': true,
    },
    lastName: {
      'ui:label': false,
      'ui:readonly': true,
    },
    continue: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  currentBookingScreen: {
    'ui:title': '‎',
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    label: {
      hotelName: {
        'ui:label': false,
        'ui:readonly': true,
      },
      arrivalDetails: {
        'ui:label': false,
        'ui:readonly': true,
      },
      checkIn: {
        'ui:label': false,
        'ui:readonly': true,
      },
      checkOut: {
        'ui:label': false,
        'ui:readonly': true,
      },
      roomNumber: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    instructionTextHtml: {
      'ui:label': false,
      'ui:readonly': true,
    },

    checkInSuccessTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    checkInSuccessMessage: {
      'ui:label': false,
      'ui:readonly': true,
    },
    incompleteRequirements: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      description: {
        'ui:label': false,
        'ui:readonly': true,
      },
      button: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    disabledCheckInText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    checkInButtonText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    nextStepButtonText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    errors: {
      idVerification: {
        AGE_VERIFICATION_FAILED: {
          header: {
            'ui:label': false,
            'ui:readonly': true,
          },
          subheader: {
            'ui:label': false,
            'ui:readonly': true,
          },
        },
        ID_VERIFICATION_DATA_MISSMATCH: {
          header: {
            'ui:label': false,
            'ui:readonly': true,
          },
          subheader: {
            'ui:label': false,
            'ui:readonly': true,
          },
        },
        VENDOR_INTEGRATION_FAILED: {
          header: {
            'ui:label': false,
            'ui:readonly': true,
          },
          subheader: {
            'ui:label': false,
            'ui:readonly': true,
          },
        },
        ID_VERIFICATION_FAILED: {
          header: {
            'ui:label': false,
            'ui:readonly': true,
          },
          subheader: {
            'ui:label': false,
            'ui:readonly': true,
          },
        },
        SERVER_ERROR: {
          header: {
            'ui:label': false,
            'ui:readonly': true,
          },
          subheader: {
            'ui:label': false,
            'ui:readonly': true,
          },
        },
      },
    },
    getQrCodeButtonText: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  idVerificationInitScreen: {
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    label: {
      passport: {
        'ui:label': false,
        'ui:readonly': true,
      },
      idCard: {
        'ui:label': false,
        'ui:readonly': true,
      },
      driverLicense: {
        'ui:label': false,
        'ui:readonly': true,
      },
      change: {
        'ui:label': false,
        'ui:readonly': true,
      },
      document: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    setCountryInstructionText1: {
      'ui:label': false,
      'ui:readonly': true,
    },
    setCountryInstructionText2: {
      'ui:label': false,
      'ui:readonly': true,
    },
    consent: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      description: {
        'ui:label': false,
        'ui:readonly': true,
      },
      privacyPolicy: {
        'ui:label': false,
        'ui:readonly': true,
      },
      buttonText: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
  },
  idVerificationScanDocumentScreen: {
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    switchCamera: {
      'ui:label': false,
      'ui:readonly': true,
    },
    capturePhoto: {
      'ui:label': false,
      'ui:readonly': true,
    },
    idCard: {
      front: {
        title: {
          'ui:label': false,
          'ui:readonly': true,
        },
        description: {
          'ui:label': false,
          'ui:readonly': true,
        },
        buttonTitle: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
      back: {
        title: {
          'ui:label': false,
          'ui:readonly': true,
        },
        description: {
          'ui:label': false,
          'ui:readonly': true,
        },
        buttonTitle: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
      face: {
        title: {
          'ui:label': false,
          'ui:readonly': true,
        },
        description: {
          'ui:label': false,
          'ui:readonly': true,
        },
        buttonTitle: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
    },
    passport: {
      front: {
        title: {
          'ui:label': false,
          'ui:readonly': true,
        },
        description: {
          'ui:label': false,
          'ui:readonly': true,
        },
        buttonTitle: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
      face: {
        title: {
          'ui:label': false,
          'ui:readonly': true,
        },
        description: {
          'ui:label': false,
          'ui:readonly': true,
        },
        buttonTitle: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
    },
    driverLicense: {
      front: {
        title: {
          'ui:label': false,
          'ui:readonly': true,
        },
        description: {
          'ui:label': false,
          'ui:readonly': true,
        },
        buttonTitle: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
      back: {
        title: {
          'ui:label': false,
          'ui:readonly': true,
        },
        description: {
          'ui:label': false,
          'ui:readonly': true,
        },
        buttonTitle: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
      face: {
        title: {
          'ui:label': false,
          'ui:readonly': true,
        },
        description: {
          'ui:label': false,
          'ui:readonly': true,
        },
        buttonTitle: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
    },
    frontOf: {
      'ui:label': false,
      'ui:readonly': true,
    },
    backOf: {
      'ui:label': false,
      'ui:readonly': true,
    },
    faceCapture: {
      'ui:label': false,
      'ui:readonly': true,
    },
    passportText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    idCardText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    driverLicenseText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    document: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  idVerificationResultScreen: {
    waitingIdVerificationResult: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      description: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    idVerificationFailed: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      description: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    idVerificationSuccess: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      description: {
        'ui:label': false,
        'ui:readonly': true,
      },
      button: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
  },
  paymentStatusScreen: {
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    instructionText1: {
      'ui:label': false,
      'ui:readonly': true,
    },
    instructionText2: {
      'ui:label': false,
      'ui:readonly': true,
    },
    label: {
      weAccept: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    success: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      description: {
        'ui:label': false,
        'ui:readonly': true,
      },
      button: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    fail: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      description: {
        'ui:label': false,
        'ui:readonly': true,
      },
      button: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    paymentDisabled: {
      instructionText1: {
        'ui:label': false,
        'ui:readonly': true,
      },
      instructionText2: {
        'ui:label': false,
        'ui:readonly': true,
      },
      button: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
  },
  paymentAuthorizationSuccessScreen: {
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    description: {
      'ui:label': false,
      'ui:readonly': true,
    },
    button: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  paymentAuthorizationErrorScreen: {
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    description: {
      'ui:label': false,
      'ui:readonly': true,
    },
    button: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  termsAndConditionsScreen: {
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    label: {
      signature: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    success: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      description: {
        'ui:label': false,
        'ui:readonly': true,
      },
      button: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    fail: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      description: {
        'ui:label': false,
        'ui:readonly': true,
      },
      button: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
  },
  successScreen: {
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    descriptionHtml: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  userDetailsScreen: {
    guests: {
      'ui:label': false,
      'ui:readonly': true,
    },
    nameOnReservation: {
      'ui:label': false,
      'ui:readonly': true,
    },
    otherGuests: {
      'ui:label': false,
      'ui:readonly': true,
    },
    header: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      description: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    noResourceFound: {
      'ui:label': false,
      'ui:readonly': true,
    },
    footerButtonText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    edit: {
      'ui:label': false,
      'ui:readonly': true,
    },
    email: {
      'ui:label': false,
      'ui:readonly': true,
    },
    phoneNumber: {
      'ui:label': false,
      'ui:readonly': true,
    },
    save: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  roomNotReadyScreen: {
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    descriptionHtml: {
      'ui:label': false,
      'ui:readonly': true,
    },
    button: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  packagesScreen: {
    prebookHeader: {
      'ui:label': false,
      'ui:readonly': true,
    },
    skipButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    viewCart: {
      'ui:label': false,
      'ui:readonly': true,
    },
    singlePrebook: {
      'ui:label': false,
      'ui:readonly': true,
    },
    emptyPrebook: {
      'ui:label': false,
      'ui:readonly': true,
    },
    subtitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    seePackageText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    booked: {
      'ui:label': false,
      'ui:readonly': true,
    },
    add: {
      'ui:label': false,
      'ui:readonly': true,
    },
    addModal: {
      'ui:label': false,
      'ui:readonly': true,
    },
    packages: {
      'ui:label': false,
      'ui:readonly': true,
    },
    cart: {
      'ui:label': false,
      'ui:readonly': true,
    },
    total: {
      'ui:label': false,
      'ui:readonly': true,
    },
    submit: {
      'ui:label': false,
      'ui:readonly': true,
    },
    subheader: {
      'ui:label': false,
      'ui:readonly': true,
    },
    description: {
      'ui:label': false,
      'ui:readonly': true,
    },
    category: {
      'ui:label': false,
      'ui:readonly': true,
    },
    categories: {
      'ui:label': false,
      'ui:readonly': true,
    },
    cancel: {
      'ui:label': false,
      'ui:readonly': true,
    },
    apply: {
      'ui:label': false,
      'ui:readonly': true,
    },
    filter: {
      'ui:label': false,
      'ui:readonly': true,
    },
    cartSubheader: {
      'ui:label': false,
      'ui:readonly': true,
    },
    cartDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    successTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    successDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    successButtonTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    failTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    failDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    failButtonTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    emptyCartTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    emptyCartDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  paymentCaptureScreen: {
    notSupportPaymentProviderText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    paymentDisabled: {
      paymentDisabledText1: {
        'ui:label': false,
        'ui:readonly': true,
      },
      paymentDisabledText2: {
        'ui:label': false,
        'ui:readonly': true,
      },
      button: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    creditCardCaptureLeft: {
      'ui:label': false,
      'ui:readonly': true,
    },
    creditCardCaptureRight: {
      'ui:label': false,
      'ui:readonly': true,
    },
    description: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  shareKeyScreen: {
    tutorialScreen: {
      header: {
        'ui:label': false,
        'ui:readonly': true,
      },
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      description: {
        'ui:label': false,
        'ui:readonly': true,
      },
      nextButton: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    guestListScreen: {
      header: {
        'ui:label': false,
        'ui:readonly': true,
      },
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      shareKeyButton: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    shareKeyScreen: {
      header: {
        'ui:label': false,
        'ui:readonly': true,
      },
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      shareKeyButton: {
        'ui:label': false,
        'ui:readonly': true,
      },
      formPlaceholders: {
        firstName: {
          'ui:label': false,
          'ui:readonly': true,
        },
        lastName: {
          'ui:label': false,
          'ui:readonly': true,
        },
        email: {
          'ui:label': false,
          'ui:readonly': true,
        },
        phoneNumber: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
      successPopup: {
        title: {
          'ui:label': false,
          'ui:readonly': true,
        },
        description: {
          'ui:label': false,
          'ui:readonly': true,
        },
        okButton: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
      errorPopup: {
        title: {
          'ui:label': false,
          'ui:readonly': true,
        },
        description: {
          'ui:label': false,
          'ui:readonly': true,
        },
        okButton: {
          'ui:label': false,
          'ui:readonly': true,
        },
      },
    },
    shareKeyButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  nameAndDepartureDateVerificationScreen: {
    emptyData: {
      'ui:label': false,
      'ui:readonly': true,
    },
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    description: {
      'ui:label': false,
      'ui:readonly': true,
    },
    location: {
      'ui:label': false,
      'ui:readonly': true,
    },
    firstName: {
      'ui:label': false,
      'ui:readonly': true,
    },
    lastName: {
      'ui:label': false,
      'ui:readonly': true,
    },
    departureDate: {
      'ui:label': false,
      'ui:readonly': true,
    },
    errorMessage: {
      'ui:label': false,
      'ui:readonly': true,
    },
    continue: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  userInformationScreen: {
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    subtitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    detailScreenHeader: {
      'ui:label': false,
      'ui:readonly': true,
    },
    editScreenHeader: {
      'ui:label': false,
      'ui:readonly': true,
    },
    guests: {
      'ui:label': false,
      'ui:readonly': true,
    },
    nameOnReservation: {
      'ui:label': false,
      'ui:readonly': true,
    },
    otherGuests: {
      'ui:label': false,
      'ui:readonly': true,
    },
    continue: {
      'ui:label': false,
      'ui:readonly': true,
    },
    guestDetails: {
      'ui:label': false,
      'ui:readonly': true,
    },
    guestDetailsDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    update: {
      'ui:label': false,
      'ui:readonly': true,
    },
    editButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  obfuscatedAuthScreen: {
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    subtitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    emailLabel: {
      'ui:label': false,
      'ui:readonly': true,
    },
    phoneNumberLabel: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  confirmationCodeVerificationScreen: {
    emptyData: {
      'ui:label': false,
      'ui:readonly': true,
    },
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    description: {
      'ui:label': false,
      'ui:readonly': true,
    },
    location: {
      'ui:label': false,
      'ui:readonly': true,
    },
    confirmationCode: {
      'ui:label': false,
      'ui:readonly': true,
    },
    errorMessage: {
      'ui:label': false,
      'ui:readonly': true,
    },
    continue: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  expectedArrivalScreen: {
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    description: {
      'ui:label': false,
      'ui:readonly': true,
    },
    submitButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    alertSuccessTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    alertSuccessDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    alertSuccessOkButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    alertErrorTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    alertErrorDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    alertErrorOkButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    alertConfirmationTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    alertConfirmationDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    alertConfirmationCancelButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    alertConfirmationApplyButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    switchLabel: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  checkoutScreen: {
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    cardOnFileTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    itemHeader: {
      'ui:label': false,
      'ui:readonly': true,
    },
    priceHeader: {
      'ui:label': false,
      'ui:readonly': true,
    },
    totalPriceHeader: {
      'ui:label': false,
      'ui:readonly': true,
    },
    totalFooter: {
      'ui:label': false,
      'ui:readonly': true,
    },
    checkoutButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    checkoutFailed: {
      'ui:label': false,
      'ui:readonly': true,
    },
    checkOutFailureAlertTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    checkOutSuccessfulAlertTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    checkOutSuccessfulAlertText: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  remoteAssistanceModal: {
    directMessage: {
      'ui:label': false,
      'ui:readonly': true,
    },
    voice: {
      'ui:label': false,
      'ui:readonly': true,
    },
    video: {
      'ui:label': false,
      'ui:readonly': true,
    },
    end: {
      'ui:label': false,
      'ui:readonly': true,
    },
    mute: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  scanBarcodeScreen: {
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    header: {
      'ui:label': false,
      'ui:readonly': true,
    },
    subheader: {
      'ui:label': false,
      'ui:readonly': true,
    },
    success: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      description: {
        'ui:label': false,
        'ui:readonly': true,
      },
      button: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    close: {
      'ui:label': false,
      'ui:readonly': true,
    },
    fail: {
      title: {
        'ui:label': false,
        'ui:readonly': true,
      },
      description: {
        'ui:label': false,
        'ui:readonly': true,
      },
      button: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
    instruction: {
      header: {
        'ui:label': false,
        'ui:readonly': true,
      },
      subheader: {
        'ui:label': false,
        'ui:readonly': true,
      },
      info: {
        'ui:label': false,
        'ui:readonly': true,
      },
      imageTitle: {
        'ui:label': false,
        'ui:readonly': true,
      },
      buttonText: {
        'ui:label': false,
        'ui:readonly': true,
      },
    },
  },
  scheduleCheckInScreen: {
    title: {
      'ui:label': false,
      'ui:readonly': true,
    },
    description: {
      'ui:label': false,
      'ui:readonly': true,
    },
    submitButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    alertSuccessTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    alertSuccessDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    alertSuccessOkButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    alertErrorTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    alertErrorDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    alertErrorOkButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    alertConfirmationTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    alertConfirmationDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    alertConfirmationCancelButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    alertConfirmationApplyButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    switchLabel: {
      'ui:label': false,
      'ui:readonly': true,
    },
    dateNotSelectedAlertTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    featureNotEnabledTitle: {
      'ui:label': false,
      'ui:readonly': true,
    },
    scheduleDateHasBeenSetText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    selectDate: {
      'ui:label': false,
      'ui:readonly': true,
    },
    timeCaption: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
  faceLivenessScreen: {
    'ui:title': '‎',
    backLabel: {
      'ui:label': false,
      'ui:readonly': true,
    },
    cameraText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    instructionText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    instructionDescriptionText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    backAlert: {
      'ui:label': false,
      'ui:readonly': true,
    },
    remoteAssistanceAlert: {
      'ui:label': false,
      'ui:readonly': true,
    },
    homeAlert: {
      'ui:label': false,
      'ui:readonly': true,
    },
    backAlertPositiveButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    remoteAssistanceAlertPositiveButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    homeAlertPositiveButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    backAlertNegativeButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    remoteAssistanceAlertNegativeButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    homeAlertNegativeButton: {
      'ui:label': false,
      'ui:readonly': true,
    },
    headerText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    subheaderText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    startButtonText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    faceMatchFailedDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
    retryButtonText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    endSessionButtonText: {
      'ui:label': false,
      'ui:readonly': true,
    },
    faceLivenessFailedDescription: {
      'ui:label': false,
      'ui:readonly': true,
    },
  },
};
