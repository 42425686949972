import { ActionResult } from '../@types/QuickActionBarTypes';
import POLL_RESERVATION_FROM_PMS from '../../../graphql/pollReservationFromPMS';
import PollReservationInputs from '../../AccessGrantPollReservationForm/@types/PollReservationInputs';
import PollReservationTypesData from '../../AccessGrantPollReservationForm/@types/PollReservationTypesData';
import { channelsClient } from '../../../util/pusher';
import pollReservationTimeout from '../../../hooks/usePollReservations/constants/pollReservationTimeout';
import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
const { REACT_APP_VIRDEE_ENV = 'local' } = process.env;

type UseRepollReservationResponse = {
  repollReservation: () => Promise<ActionResult>;
};

export const useRepollReservation = (args: PollReservationInputs): UseRepollReservationResponse => {
  const [pollReservation] = useMutation<PollReservationTypesData, PollReservationInputs>(
    POLL_RESERVATION_FROM_PMS
  );

  const getPollingResponse = useCallback((): Promise<ActionResult> => {
    const { confirmationCode, tenantId, locationId } = args;
    const POLL_RESERVATION_EVENT_NAME = 'POLL_RESERVATION';
    const channelName = `ACCESS_GRANT-${REACT_APP_VIRDEE_ENV}-${tenantId}-${locationId}-${confirmationCode}-${POLL_RESERVATION_EVENT_NAME}`;
    const accessGrantChannel = channelsClient.subscribe(channelName);

    return Promise.race<ActionResult>([
      new Promise<ActionResult>((resolve) => {
        if (confirmationCode) {
          accessGrantChannel.bind(POLL_RESERVATION_EVENT_NAME, (data: { success: boolean }) => {
            accessGrantChannel.unbind(POLL_RESERVATION_EVENT_NAME);

            const { success } = data;
            resolve({
              success: data.success,
              errorMessage: !success ? 'Error polling reservation' : undefined,
            });
          });
        }
      }),
      new Promise<ActionResult>((resolve) => {
        setTimeout(() => {
          const result: ActionResult = {
            success: false,
            errorMessage: 'Polling operation timed out',
          };

          resolve(result);
        }, pollReservationTimeout);
      }),
    ]);
  }, []);

  const repollReservation = useCallback(async (): Promise<ActionResult> => {
    try {
      const response = await pollReservation({
        variables: args,
      });

      if (!response.data?.pollReservationFromPms) {
        return {
          success: false,
          errorMessage: 'Failed to poll reservation. Please try again',
        };
      }

      const pollingResponse = await getPollingResponse();

      return pollingResponse;
    } catch (error) {
      return {
        success: false,
        errorMessage: 'Exception while polling reservation',
      };
    }
  }, []);

  return {
    repollReservation,
  };
};
