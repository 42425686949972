/* eslint-disable */
import React from 'react';
import dragIcon from '../../../assets/icons/dragIcon.png';
import folderIcon from '../../../assets/icons/folderIcon.png';
import infoIcon from '../../../assets/icons/infoIcon.png';
import styles from './styles.module.css';

const NodeIcon = ({ type, handleRef }) => {
  if (type === 'category') {
    return (
      <div>
        <img src={folderIcon} className={styles.conciergeInfoIcon} />
        <img
          src={dragIcon}
          className={styles.conciergeInfoIcon}
          ref={handleRef}
          style={{ cursor: 'pointer' }}
        />
      </div>
    );
  }
  if (type === 'info') {
    return (
      <div>
        <img src={infoIcon} className={styles.conciergeInfoIcon} />
        <img
          src={dragIcon}
          className={styles.conciergeInfoIcon}
          ref={handleRef}
          style={{ cursor: 'pointer' }}
        />
      </div>
    );
  }
  return null;
};

export default NodeIcon;
