import {
  Mutation,
  MutationPerformCheckInArgs,
  MutationPerformCheckOutArgs,
} from '../../../__generated__/graphql';

import { ActionResult } from '../@types/QuickActionBarTypes';
import { PERFORM_CHECK_IN } from '../../../graphql/performCheckIn';
import { PERFORM_CHECK_OUT } from '../../../graphql/performCheckOut';
import { getErrorMessage } from '../../../util/errorUtils';
import { useCallback } from 'react';
import { useMutation } from '@apollo/client';

type UsePerformCheckInOut = {
  performCheckIn: () => Promise<ActionResult>;
  performCheckOut: () => Promise<ActionResult>;
};

export const usePerformCheckInOut = (accessGrantId: string): UsePerformCheckInOut => {
  const [sendPerformCheckInMutation] = useMutation<
    Pick<Mutation, 'performCheckIn'>,
    MutationPerformCheckInArgs
  >(PERFORM_CHECK_IN);

  const [sendPerformCheckOutMutation] = useMutation<
    Pick<Mutation, 'performCheckOut'>,
    MutationPerformCheckOutArgs
  >(PERFORM_CHECK_OUT);

  const performCheckIn = useCallback(async (): Promise<ActionResult> => {
    try {
      const checkInResult = await sendPerformCheckInMutation({
        variables: {
          accessGrantId,
        },
      });

      const success = checkInResult.data?.performCheckIn.success === true;

      return {
        success,
        errorMessage: checkInResult.errors?.[0]?.message,
      };
    } catch (error) {
      return {
        success: false,
        errorMessage: getErrorMessage(error),
      };
    }
  }, []);

  const performCheckOut = useCallback(async (): Promise<ActionResult> => {
    try {
      const checkOutResult = await sendPerformCheckOutMutation({
        variables: {
          accessGrantId,
        },
      });

      const success = checkOutResult.data?.performCheckOut.success === true;

      return {
        success,
        errorMessage: checkOutResult.errors?.[0]?.message,
      };
    } catch (error) {
      return {
        success: false,
        errorMessage: getErrorMessage(error),
      };
    }
  }, []);

  return {
    performCheckIn,
    performCheckOut,
  };
};
