import { BaseModalFooterContainer } from '../styles/BaseModalFooter.styles';
import { BaseModalFooterProps } from '../@types/QuickActionBarTypes';
import Button from '../../Button';
import ButtonModel from '../../Button/enums/ButtonModel.enum';
import React from 'react';

const BaseModalFooter: React.FC<BaseModalFooterProps> = ({ confirmButton, cancelButton }) => {
  return (
    <BaseModalFooterContainer>
      {cancelButton?.action && (
        <Button model={ButtonModel.QUICK_ACTION_MODAL_CANCEL} onClick={cancelButton.action}>
          {cancelButton.text ?? 'Cancel'}
        </Button>
      )}
      {confirmButton?.action && (
        <Button model={ButtonModel.QUICK_ACTION_MODAL_CONFIRM} onClick={confirmButton.action} bold>
          {confirmButton.text ?? 'Confirm'}
        </Button>
      )}
    </BaseModalFooterContainer>
  );
};

export default BaseModalFooter;
