import React, { useMemo } from 'react';
import { openForm, openQuickActionProcessingModal } from '../../../redux/actions/modals.actions';
import { useDispatch, useSelector } from 'react-redux';
import { ACCESS_GRANT_AUTHENTICATION_CREDENTIALS_FORM } from '../../AccessGrantAuthenticationCredentials';
import { AccessGrantPageInfo } from '../../../pages/AccessGrant/@types/GetAccessGrantPageInfo';
import { AccessGrantState } from '../../../__generated__/graphql';
import { ActionButton } from './ActionButton';
import { ActionButtonSkeleton } from './ActionButtonSkeleton';
import { KEY_DISPENSE_FORM } from './RemoteKeyDispensingModal';
import { List } from '../styles/ActionsList.styles';
import { QuickActionButtonsList } from '../@types/QuickActionBarTypes';
import { RootState } from '../../../redux/reducers';
import { agetActionButtonState } from '../util/actionButtonStateMachine';
import { isEmpty } from 'lodash';
import useCheckInLock from '../../../pages/AccessGrant/hooks/useCheckInLock';
import { usePerformCheckInOut } from '../hooks/usePerformCheckInOut';
import { useRepollReservation } from '../hooks/useRepollReservation';

export const ActionsList: React.FC = () => {
  const dashboardPreferences = useSelector((state: RootState) => state.dashboardPreferences);
  const accessGrant = useSelector((state: RootState) => state.accessGrant.pageInfo);
  const dispatch = useDispatch();
  const { updateCheckInLockStatus, isCheckInEnabled } = useCheckInLock(
    accessGrant || ({} as AccessGrantPageInfo)
  );
  const { performCheckIn, performCheckOut } = usePerformCheckInOut(accessGrant?.id || '');
  const { repollReservation } = useRepollReservation({
    confirmationCode: accessGrant?.confirmationCode || '',
    locationId: accessGrant?.location.id || '',
    tenantId: accessGrant?.tenantId || '',
  });

  const quickActionButtonsList: QuickActionButtonsList = [
    {
      action: (): void => {
        dispatch(
          openQuickActionProcessingModal({
            action: performCheckIn,
            ctaType: 'checkIn',
          })
        );
      },
      ctaType: 'checkIn',
      displayTopSeparatorBar: true,
      text: 'Check-In',
    },
    {
      action: (): void => {
        dispatch(
          openQuickActionProcessingModal({
            action: performCheckOut,
            ctaType: 'checkOut',
          })
        );
      },
      ctaType: 'checkOut',
      text: 'Check-Out',
    },
    {
      action: (): void => {
        dispatch(
          openQuickActionProcessingModal({
            action: updateCheckInLockStatus,
            ctaType: 'blockCheckIn',
          })
        );
      },
      ctaType: 'blockCheckIn',
      displayTopSeparatorBar: true,
      text: `${isCheckInEnabled ? 'Block' : 'Unblock'} Check-In`,
    },
    {
      action: (): void => {
        const accessGrantId = accessGrant?.id;

        if (accessGrantId) {
          dispatch(openForm(ACCESS_GRANT_AUTHENTICATION_CREDENTIALS_FORM, accessGrantId));
        }
      },
      ctaType: 'getAutenticationCredentials',
      text: 'Authentication Credentials',
    },
    {
      action: (): void => {
        dispatch(openForm(KEY_DISPENSE_FORM));
      },
      ctaType: 'remotePrintKeyCard',
      displayTopSeparatorBar: true,
      text: 'Dispense Key',
    },
    {
      action: (): void => {
        dispatch(
          openQuickActionProcessingModal({
            action: repollReservation,
            ctaType: 'repollReservation',
          })
        );
      },
      ctaType: 'repollReservation',
      text: 'Re-Poll',
      conditionToDisplay: accessGrant?.location.supportsPolling,
    },
  ];

  const enabledQuickActions = useMemo((): QuickActionButtonsList => {
    const preferencesActionsList = Object.entries(
      dashboardPreferences?.quickActionBar?.actions || {}
    );

    return quickActionButtonsList.filter(({ ctaType, conditionToDisplay }) => {
      const conditionToDisplayIsSatisfied =
        typeof conditionToDisplay === 'boolean' ? conditionToDisplay : true;

      const ctaTypeIsDisabledInThePreferences = preferencesActionsList.find(
        ([ctaTypeKey, ctaIsActive]) => {
          return ctaType === ctaTypeKey && ctaIsActive === false;
        }
      );

      return isEmpty(ctaTypeIsDisabledInThePreferences) && conditionToDisplayIsSatisfied;
    });
  }, [dashboardPreferences]);

  return (
    <List>
      {enabledQuickActions?.length > 0 ? (
        enabledQuickActions.map(({ action, ctaType, text, displayTopSeparatorBar }, index) => {
          const agState = accessGrant?.state || AccessGrantState.PreCheckIn;
          const isButtonEnabled = agetActionButtonState(ctaType, agState);

          return (
            <ActionButton
              action={action}
              ctaType={ctaType}
              text={text}
              key={`${index}_${text}`}
              disabled={!isButtonEnabled}
              displayTopSeparatorBar={displayTopSeparatorBar}
            />
          );
        })
      ) : (
        <ActionButtonSkeleton />
      )}
    </List>
  );
};
