import { Query, QueryKiosksArgs, SortOrder } from '../../../__generated__/graphql';
import GET_KIOSKS from '../../../graphql/getKiosks';
import { RootState } from '../../../redux/reducers';
import SelectOption from '../../../@types/react/SelectOption';
import { useApolloClient } from '@apollo/client';
import { useSelector } from 'react-redux';

type KiosksData = Pick<Query, 'kiosks'>;

export default function useLoadKioskOptions(): (input: string) => Promise<SelectOption[]> {
  const client = useApolloClient();
  const accessGrant = useSelector((state: RootState) => state.accessGrant.pageInfo);

  return async (input: string): Promise<SelectOption[]> => {
    const { data } = await client.query<KiosksData, QueryKiosksArgs>({
      query: GET_KIOSKS,
      variables: {
        orderBy: [
          {
            name: SortOrder.Asc,
          },
        ],
        take: 3,
        where: {
          name: {
            contains: input,
          },
          locationId: {
            equals: accessGrant?.location.id,
          },
        },
      },
    });

    return (
      data.kiosks?.map((kiosk) => ({
        label: kiosk.name,
        value: kiosk.id,
      })) ?? []
    );
  };
}
