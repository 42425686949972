import { Query, QueryKiosksArgs } from '../../../__generated__/graphql';
import QueryHookData from '../../../hooks/@types/QueryHookData';
import QueryHookParams from '../../../hooks/@types/QueryHookParams';
import SEARCH_KIOSKS from '../../../graphql/searchKiosks';
import { useLazyQuery } from '@apollo/client';

type SearchKiosksData = Pick<Query, 'kiosks'>;

export default function useSearchKioskByName(params?: QueryHookParams): QueryHookData {
  const { onCompleted = (): void => {}, onError = (): void => {}, searchValue = '' } = params ?? {};

  const [getKiosks, { data, error, loading }] = useLazyQuery<SearchKiosksData, QueryKiosksArgs>(
    SEARCH_KIOSKS,
    {
      onCompleted,
      onError,
    }
  );

  const kiosks = data?.kiosks ?? [];

  return {
    data: kiosks.map((kiosk) => ({
      id: kiosk.id,
      name: kiosk.name,
    })),
    error,
    loading,
    query: (name = ''): void => {
      getKiosks({
        variables: {
          take: 10,
          where: {
            name: { contains: name ?? searchValue },
          },
        },
      });
    },
  };
}
