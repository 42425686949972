import React from 'react';
import TextContent from '../../TextContent';
import styles from '../index.module.css';

const ConciergeTreeExplain = () => {
  return (
    <div className={styles.explainContainer}>
      <div className={styles.explainWrapper}>
        <TextContent fontSize="16px" color="#FFFFFF">
          Info Items and Categories can be easily reordered using a drag-and-drop interface.
          Additionally, you have the ability to establish hierarchical structures by nesting Info
          Items under specific Categories and also nesting Categories within one another. The system
          supports a maximum hierarchy depth of up to 3 levels, providing flexible organization.
        </TextContent>
      </div>
    </div>
  );
};

export default ConciergeTreeExplain;
