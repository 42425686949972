/* eslint-disable */
import React, { FC } from 'react';

import Button from '../../Button';
import ButtonModel from '../../Button/enums/ButtonModel.enum';
import TextContent from '../../TextContent';
import plusIcon from '../../../assets/icons/plusIcon.png';
import styles from '../index.module.css';

interface HeaderProps {
  onPress: () => void;
}

const ConciergeTreeHeader: FC<HeaderProps> = ({ onPress }) => {
  return (
    <div className={styles.headerContainer}>
      <div className={styles.headerWrapper}>
        <div className={styles.headerTitleContainer}>
          <TextContent fontSize="24px" color="#FFFFFF">
            Info Items
          </TextContent>
        </div>
        <div className={styles.createItemBtnContainer}>
          <Button
            model={ButtonModel.ACTIVE_CTA}
            onClick={onPress}
            className={styles.createItemButton}
          >
            <img src={plusIcon} width={14} height={14} style={{ marginRight: 8 }} />
            Create Item
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConciergeTreeHeader;
